@import './utils-mixins'
@import './media-query-mixins'
@import './positioning-mixins'
@import './button-mixins'

=disabled-input
  cursor: not-allowed !important
  background-color: var(--grey-trans-100) !important
  border-color: var(--grey-trans-150) !important
  background-image: none !important
  color: var(--text-disabled) !important
  &.disabled-alt
    background-color: var(--grey-150) !important
  &::placeholder
    color: var(--text-disabled) !important

=input($pad: var(--blh-50))
  width: 100%
  padding: $pad
  font-family: var(--font-primary)
  font-size: var(--sm)
  color: var(--input-text-colour)
  border: var(--border-solid-light)
  border-radius: var(--radius)
  background-color: var(--input-bg-colour)
  &:focus
    border-color: var(--border-color-focus)
    box-shadow: var(--shadow-input-focused)
    outline: none
    &.error
      box-shadow: var(--shadow-input-focused-danger)
  &:disabled
    +disabled-input
  &.error
    border-color: var(--danger)
    & + span.error
      color: var(--danger)
      font-size: var(--xs)

=borderless-input($height: auto, $pad: 0)
  position: relative
  height: $height
  flex-grow: 1
  padding: $pad
  text-overflow: ellipsis
  outline: none
  border: none
  box-shadow: none
  &:disabled
    +disabled-input
  &:focus
    box-shadow: none

=error-list
  max-height: 10em
  width: 100%
  background-color: var(--error-list-bg-colour)
  overflow-y: auto
  ul
    +unbulleted-list
  li,
  .drop-list-error
    max-width: 100%
    padding: var(--blh-50) var(--blh)
    text-align: center
    border-bottom: var(--border)
  li:last-child,
  .drop-list-error:last-child
    border-bottom: none

=form-errors
  list-style: none
  top: 0
  margin-bottom: var(--blh-25)
  padding: 0
  li
    margin: 0
    padding: 0
    line-height: var(--line-height-tight)
    font-size: var(--sm)
    color: var(--danger)

%label
  margin-bottom: var(--blh-25)
  font-size: var(--sm)
  span.form-error
    margin-left: var(--blh-25)
    +tablet
    font-size: var(--sm)

=label
  @extend %label

=aql-label
  display: block
  margin-bottom: var(--blh-25)
  font-size: var(--xs)
  color: var(--text-mid2)

%input-group
  width: 100%
  margin-bottom: var(--blh-50)
  &:last-child
    margin-bottom: 0
  .form-errors
    +form-errors
  label
    @extend %label
    margin-left: var(--radius)

=input-group
  @extend %input-group

=input-group-center
  +flex-col(center, flex-start)
  @extend %input-group

=dynamic-form-display
  display: grid
  grid-template-columns: minmax(min-content, 26em)
  justify-content: center
  justify-self: center

=select-light-mode-colour-properties($important: false)
  @if ($important == true)
    color: var(--grey-800) !important
    border-bottom: var(--border-solid-light-med) !important
    .icon
      color: var(--grey-800) !important
  @else
    color: var(--grey-800)
    border-bottom: var(--border-solid-light-med)
    .icon
      color: var(--grey-800)
=select-light-mode-colour-properties-active($important: false)
  @if ($important == true)
    border-bottom: var(--border-solid-dark-med) !important
    .icon
      +icon-background-highlight(var(--grey-trans-100), $important)
  @else
    border-bottom: var(--border-solid-dark-med)
    .icon
      +icon-background-highlight
=select-dark-mode-colour-properties($important: false)
  @if ($important == true)
    color: var(--light) !important
    border-bottom: var(--border-solid-d600) !important
    .icon
      color: var(--light) !important
  @else
    color: var(--light)
    border-bottom: var(--border-solid-d600)
    .icon
      color: var(--light)
=select-dark-mode-colour-properties-active($important: false)
  @if ($important == true)
    border-bottom: var(--border-solid-d400) !important
    .icon
      +icon-background-highlight(var(--white-trans-100), $important)
  @else
    border-bottom: var(--border-solid-d400)
    .icon
      +icon-background-highlight(var(--white-trans-100))

=inline-select($mode: 'light')
  .select-btn
    height: initial
    padding: 0
    padding-bottom: var(--blh-25)
    background-color: transparent
    outline: none
    border-top: none
    border-left: none
    border-right: none
    border-radius: 0
    box-shadow: none
    font-size: var(--xs)
    line-height: 1
    @if ($mode == 'dark')
      +select-dark-mode-colour-properties
    @else
      +select-light-mode-colour-properties
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.open
      border-top: none
      border-left: none
      border-right: none
      box-shadow: none
      @if ($mode == 'dark')
        +select-dark-mode-colour-properties-active
      @else
        +select-light-mode-colour-properties-active
    .btn-text
      justify-self: center

=inline-select-colourize($mode: 'light', $important: false)
  .select-btn
    @if ($mode == 'dark')
      +select-dark-mode-colour-properties($important)
    @else
      +select-light-mode-colour-properties($important)
    &:hover,
    &:focus,
    &:active,
    &.open
      @if ($mode == 'dark')
        +select-dark-mode-colour-properties-active($important)
      @else
        +select-light-mode-colour-properties-active($important)

=inline-select-highlight-active($highlightColour: var(--accent-trans-800), $iconColour: var(--accent-icon-colour))
  .select-btn
    +icon-background-highlight-active-colorize($highlightColour, $iconColour, true)


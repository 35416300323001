html
  font-size: 16px
  box-sizing: border-box
  overflow: hidden

*,
*:before,
*:after
  box-sizing: inherit

// responsive images
img,
svg
  width: 100%
  max-width: 100%

fa-icon svg
  width: initial
  max-width: initial

// global styles
html,
body,
.body-wrapper
  min-height: 100vh
  height: max-content
  width: 100%

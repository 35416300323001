=sizing-variables
  // Line heights
  --line-height: 1.5
  --line-height-headers: 1.3
  --line-height-tight: 1.2
  //* sizes use minor third scale. see https://type-scale.com/ *//
  --xxxs: 0.579rem
  --xxs: 0.694rem
  --xs: 0.833rem
  --sm: 1rem
  --m: 1.2rem
  --m2: 1.44rem
  --l: 1.728rem
  --xl: 2.074rem
  --xxl: 2.488rem
  --super: 2.956rem
  --super-xl: 3.583rem
  --super-xxl: 4.3rem
  --super-xxxl: 5.16rem
  //* spacing variables *//
  --blh-25: calc(var(--sm) / 4)
  --blh-50: calc(var(--sm) / 2)
  --blh-75: calc(var(--sm) / 1.5)
  --blh: var(--sm)
  --blh-150: calc(var(--sm) * 1.5)
  --blh-200: calc(var(--sm) * 2)
  --blh-250: calc(var(--sm) * 2.5)
  --blh-300: calc(var(--sm) * 3)
  --blh-400: calc(var(--sm) * 4)
  --blh-500: calc(var(--sm) * 5)
  --blh-600: calc(var(--sm) * 6)
  --blh-700: calc(var(--sm) * 7)
  --blh-800: calc(var(--sm) * 8)
  --blh-1000: calc(var(--sm) * 10)
  --blh-1200: calc(var(--sm) * 12)
  // HEIGHTS AND WIDTHS
  --navbar-height-val: 66
  --navbar-height: 66px
  --center-container-pad: var(--blh)
  --input-height: 2rem
  --input-height--sm: 1.85rem
  --main-content-height: calc(100vh - var(--navbar-height))
  --sidebar-width: 16
  --collapsed-sidebar-width: 3.5
  --sidebar-width-em: 16em
  --collapsed-sidebar-width-em: 3.5em
  --current-sidebar-width: var(--collapsed-sidebar-width)
  --current-sidebar-width-em: calc(var(--current-sidebar-width) * 1em)
  --narrow-header-width: 6em
  --vertical-toolbar-button-size: 2.5em

=sizing-variables-tablet
  --input-height: 2.5rem

=sizing-variables-tablet-wide
  //* sizes use major third scale. see https://type-scale.com/ *//
  --xxxs: 0.512rem
  --xxs: 0.64rem
  --xs: 0.8rem
  --sm: 1rem
  --m: 1.25rem
  --m2: 1.563rem
  --l: 1.953rem
  --xl: 2.441rem
  --xxl: 3.052rem
  --super: 3.815rem
  --super-xl: 4.768rem
  --super-xxl: 5.96rem
  --super-xxxl: 7.451rem
  --navbar-height-val: 75
  --navbar-height: 75px
  --main-content-height: calc(100vh - var(--navbar-height))
  --narrow-header-width: 1.5em

=sizing-variables-desktop
  --container-width-min: 40rem
  --narrow-header-width: 6em

@import 'mixins/icon-mixins'

.icon
  +icon

.ag-row-selected .icon.icon-grey-l
  color: var(--grey-300)

// Icon animations
.fa-spin-reverse
  animation: fa-spin-reverse 2s infinite linear

.grid-cell-icon
  color: var(--grid-cell-icon-colour)

.menu-icon
  color: var(--menu-icon-colour)

.filter-link-icon
  color: var(--criteria-button-icon-colour)

div:not(.offset-body) > .advanced-qty-block .filter-link-icon
  color: var(--criteria-button-icon-colour--alt)

@keyframes fa-spin-reverse
  100%
    transform: rotate(0deg)

  0%
    transform: rotate(359deg)

=litepicker-variables
  //  LITEPICKER OVERRIDES
  --litepicker-button-apply-color-bg: var(--litepicker-accent-colour) !important
  --litepicker-button-cancel-color-bg: var(--text)!important
  --litepicker-button-next-month-color-hover: var(--litepicker-accent-colour-d100) !important
  --litepicker-button-next-month-color: var(--litepicker-accent-colour)!important
  --litepicker-button-prev-month-color-hover: var(--litepicker-accent-colour-d100) !important
  --litepicker-button-prev-month-color: var(--litepicker-accent-colour)!important
  --litepicker-button-reset-color-hover: var(--litepicker-accent-colour) !important
  --litepicker-day-color-hover: var(--litepicker-accent-colour) !important
  --litepicker-day-color: var(--text)!important
  --litepicker-footer-box-shadow-color: var(--shadow-color-400)!important
  --litepicker-highlighted-day-color: var(--text)!important
  --litepicker-is-end-color-bg: var(--litepicker-accent-colour) !important
  --litepicker-is-in-range-color: hsla(var(--litepicker-accent-colour-hue), var(--litepicker-accent-colour-saturation), var(--litepicker-accent-colour-lightness), 0.25)!important
  --litepicker-is-locked-color: var(--grey-400) !important
  --litepicker-is-start-color-bg: var(--litepicker-accent-colour) !important
  --litepicker-is-today-color: var(--litepicker-todays-colour)!important
  --litepicker-month-header-color: var(--text)!important
  --litepicker-month-week-number-color: var(--text-light)!important
  --litepicker-month-weekday-color: var(--text)!important

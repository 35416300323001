=container-variables
  // CONTAINER VARIABLES
  --container-width: 40rem
  --container-width-min: 90vw
  --container-width-wide: 60rem
  --container-width-xl: 80rem

=container-variables-phablet
  --container-width-min: 34rem


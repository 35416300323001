@charset "utf-8"
@import '@angular/cdk/overlay-prebuilt.css'
@import 'pdfjs-dist/web/pdf_viewer.css'
@import 'minireset'
@import 'variables'
@import 'mixins/mixins'
@import "css-variables/css-variables"
@import "base"
@import 'typography'
@import 'buttons'
@import 'links'
@import 'icons'
@import 'forms'
@import 'helpers'
@import 'grid.scss'
@import 'litepicker'
@import 'misc'


/** ONLY NON-COMPONENT STYLES HERE (Code outside of Angular) */

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

.body-loading-spinner
  height: 100vh
  width: 100vw
  position: absolute
  top: 0
  left: 0
  background-image: url('https://cdn.r36.cloud/rule/backgrounds/login-background-255x1495.jpg')
  background-position: bottom right
  background-size: cover
  background-repeat: no-repeat
  z-index: 9999
  display: grid
  justify-content: center
  align-items: center
  +flex-col
  opacity: 1
  transition: opacity 300ms ease-out
  span
    margin-top: var(--blh-75)
    letter-spacing: 1px
  &.inline
    flex-flow: row
    span
      margin: 0 0 0 var(--blh-25)
  .icon
    max-width: 3em
    svg
      animation: spin 3s linear infinite
  &.fade
    opacity: 0

/* Keyword values */
body
  scrollbar-color: var(--scrollbar-colour)

.sentry-error-embed-wrapper
  z-index: 1001 !important

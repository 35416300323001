@import 'mixins/form-mixins'
@import 'mixins/dropdown-mixins'

fieldset
  padding: var(--blh)
  border-radius: var(--radius)
  border: solid thin var(--primary-l200)
  text-align: left

legend
  padding: 0 var(--blh-50)
  color: var(--primary)

.input,
textarea,
input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='search'],
select,
.select
  +input

.input,
input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='search'],
select,
.select
  height: var(--input-height)
  &.--sm
    height: var(--input-height--sm)
  &.input-muted
    border-color: var(--button-light-border-colour)

.input::-webkit-calendar-picker-indicator,
input::-webkit-calendar-picker-indicator
  display: none

input[type='checkbox']
  +appearance-reset
  display: flex
  height: 1em
  width: 1em
  transition: var(--transition)
  &:focus,
  &:active
    border: 0
    outline: none
  &::before
    +flex-flex(1, 0)
    content: ''
    display: block
    background-repeat: no-repeat
    background-position: center
    background-size: 1em 1em
    background-image: var(--checkbox-unchecked)
  &:hover::before
    background-image: var(--checkbox-unchecked-hover)
  &:checked::before
    background-image: var(--checkbox-checked)
  &:hover:checked::before
    background-image: var(--checkbox-checked-hover)

input[type='checkbox'].light
  &::before
    background-image: var(--checkbox-unchecked-light)
  &:hover::before
    background-image: var(--checkbox-unchecked-light-hover)
  &:checked::before
    background-image: var(--checkbox-checked-light)
  &:hover:checked::before
    background-image: var(--checkbox-checked-light-hover)

input.select,
select,
.select
  +dropdown-input-arrow

.input::placeholder,
input::placeholder,
textarea::placeholder
  color: var(--text-mid2)

.input-btn-group
  display: grid
  grid-auto-flow: column
  grid-template-columns: minmax(10em, 20em) max-content
  align-items: stretch
  > input
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    border-right: none
  > input,
  > button,
  .btn-main,
  .btn-drop,
  .btn-drop-container
    height: 100%
  > button,
  .btn-main,
  .btn-solo
    border-top-left-radius: 0
    border-bottom-left-radius: 0


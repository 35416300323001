@import "mixins/button-mixins"
@import "mixins/link-mixins"

.link
  +link

.link-alt
  +link-alt

button.link:focus
    +icon-background-highlight

button.link:disabled
  cursor: not-allowed
  text-decoration: none
  color: var(--text-mid) !important
  opacity: 0.7
  &:hover
    text-decoration: none
    color: var(--text-mid) !important

@import typography-variables
@import controls-variables
@import colour-variables
@import sizing-variables
@import container-variables
@import margin-padding-variables
@import ag-grid-variables
@import border-variables
@import shadow-variables
@import bg-image-variables
@import litepicker-variables

\:root
  +typography-variables
  +colour-variables
  +sizing-variables
  +container-variables
  +controls-variables
  +bg-image-variables
  +border-variables
  +shadow-variables
  +ag-grid-variables
  +litepicker-variables
  +padding-variables
  +margin-padding-variables
  // RADII
  --radius-sm: 3px
  --radius: 5px
  --radius-top: var(--radius) var(--radius) 0 0
  --radius-bottom: 0 0 var(--radius) var(--radius)
  --radius-l: 8px
  --radius-xl: 12px
  // ANIMATION
  --transition: all 100ms ease
  --transition-slow: all 150ms ease
  // MISC
  --blur-amount: 3px
  --select-arrow-size: 1.5em
  --aql-spacer: .7em
  --z-index-error: 50
  --z-index-overlay: 51

  --button-transition: all 100ms ease

  --bg-image: none

@media screen and (min-width: $phablet)
  \:root
    +container-variables-phablet
    +margin-padding-variables-phablet

@media screen and (min-width: $tablet)
  \:root
    +sizing-variables-tablet
    +margin-padding-variables-tablet

@media screen and (min-width: $tablet-wide)
  \:root
    +sizing-variables-tablet-wide
    +margin-padding-variables-tablet-wide

@media screen and (min-width: $desktop)
  \:root
    +sizing-variables-desktop

@import 'utils-mixins'

%link-disabled
  color: var(--grey-150) !important
  text-decoration: none !important
  &:hover
    color: var(--grey-150) !important

=link-framework
  +appearance-reset
  cursor: pointer
  display: inline-flex
  align-items: center
  flex-wrap: nowrap
  padding: 0
  color: var(--link-text-colour)
  text-decoration: underline
  text-decoration-color: var(--link-text-decoration-colour)
  white-space: nowrap
  background-color: transparent
  border: 0
  outline: 0
  transition: var(--transition)
  letter-spacing: 1px
  +unselectable
  &:hover
    text-decoration-color: var(--link-text-decoration-colour--hover)
    .icon-clickable.icon-danger
      color: var(--danger-d100)
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--link-active-text-colour)
    text-decoration-color: var(--link-active-text-colour)
  &.link-xs
    font-size: var(--xs)
  &.link-m
    font-size: var(--m)
  &:disabled,
  &.disabled
    @extend %link-disabled
  &.link-rel
    position: relative
  &:not(.no-bg-highlight):hover
    +icon-background-highlight(var(--grey-trans-035))

%link-standard
  color: var(--text)
  &:hover
    text-decoration-color: var(--text-mid2)
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--grey-900)
    text-decoration-color: var(--grey-900)

%link-primary
  color: var(--primary)
  text-decoration-color: var(--primary-l200)
  &:hover,
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--primary-d200)
    text-decoration-color: var(--primary-d200)

%link-primary-d
  color: var(--primary-d200)
  text-decoration-color: var(--primary)
  &:hover,
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--primary-d400)
    text-decoration-color: var(--primary-d400)

%link-secondary
  color: var(--secondary)
  text-decoration-color: var(--secondary-l400)
  &:hover,
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--secondary-d200)
    text-decoration-color: var(--secondary-l200)

%link-accent
  color: var(--accent)
  text-decoration-color: var(--accent-l200)
  &:hover,
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--accent-d200)
    text-decoration-color: var(--accent-d200)

%link-grey
  color: var(--grey-600)
  &:hover
    text-decoration-color: var(--grey-700)
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--primary)
    text-decoration-color: var(--primary)

%link-light
  color: var(--vlight)
  text-decoration-color: var(--grey-300)
  app-icon
    color: var(--light)
  &:hover,
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--vlight)
    text-decoration-color: var(--vlight)
    app-icon
      color: var(--vlight)

%link-danger
  color: var(--danger)
  text-decoration-color: var(--danger-l100)
  app-icon
    color: var(--danger)
  &:hover,
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    color: var(--danger)
    text-decoration-color: var(--danger)
    app-icon
      color: var(--danger-d100)

%link-grey-300
  color: var(--grey-300)
  text-decoration-color: var(--grey-350)
  &:focus,
  &.is-focused,
  &:active,
  &.is-active,
  &:hover
    color: var(--grey-150)
    text-decoration-color: var(--grey-200)

=link
  +link-framework
  &.link-primary
    @extend %link-primary
  &.link-primary-d
    @extend %link-primary-d
  &.link-secondary
    @extend %link-secondary
  &.link-accent
    @extend %link-accent
  &.link-grey
    @extend %link-grey
  &.link-light
    @extend %link-light
  &.link-grey-300
    @extend %link-grey-300
  &.link-danger
    @extend %link-danger
  @content

=link-alt
  +link-framework
  color: var(--link-alt-text-colour)
  text-decoration-color: var(--link-alt-text-decoration-colour)
  &:hover,
  &:focus,
  &.is-focus,
  &:active,
  &.active,
  &.is-active
    color: var(--link-alt-active-text-colour)
    text-decoration-color: var(--link-alt-text-decoration-colour--hover)

// Prevents mobile touch to select and touch and hold options
=unselectable
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

%appearance-reset
  -webkit-appearance: none
  -moz-appearance: none
=appearance-reset
  -webkit-appearance: none
  -moz-appearance: none

=unbulleted-list($resetPadding: true)
  list-style: none
  @if $resetPadding == true
    padding: 0

// hides the element but does not remove it's place in the dom
// - good for grid displays where it expects an element for grid column/row structure
=hide-in-place
  opacity: 0
  visibility: hidden

=hidden
  position: absolute !important
  top: -9999px !important
  left: -9999px !important

=visually-hidden
  position: absolute
  overflow: hidden
  clip: rect(0 0 0 0)
  height: 1px
  width: 1px
  margin: -1px
  padding: 0
  border: 0

=visually-unhidden
  position: initial
  overflow: initial
  clip: initial
  height: initial
  width: initial
  margin: initial
  padding: initial
  border: initial

=hide-element-in-dom
  height: 1px
  width: 1px
  position: absolute !important
  padding: 0
  border: 0
  white-space: nowrap
  overflow: hidden
  clip: rect(0, 0, 0, 0)

// this function takes a value and converts it to a string
@function to-string($value)
  @return inspect($value)

// this function takes a colour and converts it to an encoded version
// supports key, hex, rgb, hsl
// does not support alphas
@function encode-color($string)
  @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1
    $hex: str-slice(ie-hex-str($string), 4)
    $string: unquote('#{$hex}')
    @return '%23' + $string
  @return $string

// takes a string and replaces the search string with the replace string
@function str-replace($string, $search, $replace: '')
  // we stringify it so we can replace on colours
  $stringified: to-string($string)
  $index: str-index($stringified, $search)
  @if $index
    @return str-slice($stringified, 1, $index - 1) + $replace + str-replace(str-slice($stringified, $index + str-length($search)), $search, $replace)
  @return $stringified



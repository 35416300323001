@import 'mixins/typography-mixins'

body
  text-align: left

html,
body,
li,
p,
button,
input,
textarea
  font-family: var(--font-primary)
  font-weight: var(--font-weight)
  font-size: var(--sm)
  line-height: var(--line-height)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

html,
body,
li,
p
  color: var(--text)

button,
input,
textarea
  color: var(--button-text-colour)


h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6
  @extend %title-framework

h1,
.h1,
h2,
.h2,
h3,
.h3
  line-height: var(--line-height-headers)

h1,
.h1,
.text-xxl
  @extend %h1

h2,
.h2,
.text-xl
  @extend %h2

h3,
.h3,
.text-l
  @extend %h3

h4,
.h4,
.text-m2
  @extend %h4

h5,
.h5,
.text-m
  @extend %h5

h6,
.h6,
.text-sm
  font-size: var(--sm)

.text-xs
  font-size: var(--xs)

.text-super
  font-size: var(--super)
.text-super-xl
  font-size: var(--super-xl)

.semibold
  font-weight: 600 !important

.italic
  font-style: italic

strong,
.bold
  font-weight: 700 !important

.xbold
  font-weight: 900 !important

.underline
  text-decoration: underline !important

p,
ol,
ul
  margin-bottom: var(--blh)

li
  margin-bottom: var(--blh-25)

p:last-child,
ol:last-child,
ul:last-child,
li:last-child
  margin-bottom: 0

p,
li
  max-width: var(--container-width)

ul,
ol
  text-align: left
  padding-left: 1.25em

ul
  list-style: disc

b,
.bold
  font-weight: var(--font-weight-bold)

.regular
  font-weight: var(--font-weight)

.txt-danger
  color: var(--danger) !important

.txt-success
  color: var(--success) !important

.txt-warning
  color: var(--warning) !important

.txt-warning-d100
  color: var(--warning-d100) !important

.txt-grey-m
  color: var(--text-mid) !important

.txt-white
  color: var(--white) !important

.txt-colour-dark
  color: var(--text-colour-dark)

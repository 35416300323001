=shadow-variables
  --shadow: 0 1px 10px var(--shadow-color-200)
  --shadow-dark: 0 1px 10px var(--shadow-color-400)
  --shadow-right: 0 10px 10px var(--shadow-color-600)
  --shadow-bottom: 0 8px 8px -2px var(--shadow-color-400)
  --shadow-top: 0 -8px -2px var(--shadow-color-400)

  --shadow-bottom-light: 0 4px 4px -2px var(--shadow-color-200)

  --button-light-shadow: 0 1px 6px var(--shadow-color-200)
  --button-light-shadow-hover: 0 0 2px var(--shadow-color-300)
  --button-light-shadow-focus: 0 0 6px var(--shadow-color-300)

  --button-dark-shadow: 0 1px 6px var(--shadow-color-400)
  --button-dark-shadow-focus: 0 0 6px var(--shadow-color-500)
  --button-dark-shadow-hover: 0 0 3px var(--shadow-color-500)

  --shadow-input-focused: 0 0 0 1px var(--primary-d400-trans-10)
  --shadow-input-focused-danger: 0 0 0 1px var(--danger-d400-trans-100)

  --shadow-file-preview: 3px 3px 12px 3px var(--grey-800)

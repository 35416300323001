/** GRID */
=grid($gap: var(--blh), $flow: row, $justify-content: initial, $align-items: initial, $justify-items: initial, $align-content: initial)
  display: grid
  gap: $gap
  grid-auto-flow: $flow
  @if $justify-content != initial
    justify-content: $justify-content
  @if $justify-items != initial
    justify-items: $justify-items
  @if $align-items != initial
    align-items: $align-items
  @if $align-content != initial
    align-content: $align-content

=grid-base($gap: var(--blh))
  display: grid
  grid-gap: $gap

=grid-auto-columns($gap: var(--blh))
  +grid-base($gap)
  grid-auto-flow: column

=grid-auto-rows($gap: var(--blh))
  +grid-base($gap)
  grid-auto-flow: row

=flex-cc($justify-content: center, $align-items: center, $gap: initial, $flex: flex)
  display: $flex
  justify-content: $justify-content
  align-items: $align-items
  gap: $gap

=inline-flex($justify-content: center, $align-items: center, $gap: initial)
  +flex-cc($justify-content, $align-items, $gap, inline-flex)

=flex-row($justify-content: center, $align-items: center, $gap: initial, $flex: flex)
  +flex-cc($justify-content, $align-items, $gap, $flex)
  flex-flow: row

=flex-col($justify-content: center, $align-items: center, $gap: initial, $flex: flex)
  +flex-cc($justify-content, $align-items, $gap, $flex)
  flex-flow: column

=flex-flex($grow: 1, $shrink: 1, $basis: initial)
  flex-grow: $grow
  flex-shrink: $shrink
  flex-basis: $basis

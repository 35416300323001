=colour-variables
  --is-light-theme: initial

  --013: 98.7%
  --015: 98.5%
  --025: 97.5%
  --050: 95%
  --075: 92.5%
  --100: 90%
  --150: 85%
  --200: 80%
  --250: 75%
  --300: 70%
  --350: 65%
  --400: 60%
  --450: 55%
  --500: 50%
  --550: 45%
  --600: 40%
  --650: 35%
  --700: 30%
  --750: 25%
  --800: 20%
  --850: 15%
  --900: 10%
  --950: 5%

  --grey-hue: 210
  --grey-saturation: 0%
  --black: #1A1A1A
  --grey-950: hsl(var(--grey-hue), var(--grey-saturation), var(--950))
  --grey-900: hsl(var(--grey-hue), var(--grey-saturation), var(--900))
  --grey-850: hsl(var(--grey-hue), var(--grey-saturation), var(--850))
  --grey-800: hsl(var(--grey-hue), var(--grey-saturation), var(--800))
  --grey-750: hsl(var(--grey-hue), var(--grey-saturation), var(--750))
  --grey-700: hsl(var(--grey-hue), var(--grey-saturation), var(--700))
  --grey-650: hsl(var(--grey-hue), var(--grey-saturation), var(--650))
  --grey-600: hsl(var(--grey-hue), var(--grey-saturation), var(--600))
  --grey-550: hsl(var(--grey-hue), var(--grey-saturation), var(--550))
  --grey-500: hsl(var(--grey-hue), var(--grey-saturation), var(--500))
  --grey-450: hsl(var(--grey-hue), var(--grey-saturation), var(--450))
  --grey-400: hsl(var(--grey-hue), var(--grey-saturation), var(--400))
  --grey-350: hsl(var(--grey-hue), var(--grey-saturation), var(--350))
  --grey-300: hsl(var(--grey-hue), var(--grey-saturation), var(--300))
  --grey-250: hsl(var(--grey-hue), var(--grey-saturation), var(--250))
  --grey-200: hsl(var(--grey-hue), var(--grey-saturation), var(--250))
  --grey-150: hsl(var(--grey-hue), var(--grey-saturation), var(--150))
  --grey-100: hsl(var(--grey-hue), var(--grey-saturation), var(--100))
  --grey-075: hsl(var(--grey-hue), var(--grey-saturation), var(--075))
  --grey-050: hsl(var(--grey-hue), var(--grey-saturation), var(--050))
  --grey-025: hsl(var(--grey-hue), var(--grey-saturation), var(--025))
  --grey-015: hsl(var(--grey-hue), var(--grey-saturation), var(--015))
  --grey-013: hsl(var(--grey-hue), var(--grey-saturation), var(--013))
  --white: #fff

  --disabled-grey: var(--grey-300)
  --disabled-btn-bg-colour: var(--grey-200)
  --disabled-btn-border-colour: var(--disabled-grey)

  --light-hue: var(--grey-hue)
  --light-saturation: var(--grey-saturation)
  --light-lightness: var(--100)
  --vlight: hsl(var(--light-hue), var(--light-saturation), calc(var(--light-lightness) + 7.5%))
  --light: hsl(var(--light-hue), var(--light-saturation), var(--light-lightness))
  --light-d: hsl(var(--light-hue), var(--light-saturation), calc(var(--light-lightness) - 2.5%))
  --medium-l: var(--grey-250)
  --medium: var(--grey-300)
  --dark: var(--grey-900)

  --grid-select-primary: #7089C4

  --red-hsl: hsl(0, 55%, 55%)
  --red-hex-d100: #9e2e2e
  --red-hue: 0
  --red-saturation: 55%
  --red-lightness: 55%
  --red: hsl(var(--red-hue), var(--red-saturation), var(--red-lightness))

  --orange-hue: 23
  --orange-saturation: 56%
  --orange-lightness: 46%
  --orange: hsl(var(--orange-hue), var(--orange-saturation), var(--orange-lightness))
  --orange-d050: hsl(var(--orange-hue), var(--orange-saturation), calc(var(--orange-lightness) - 5%))
  --orange-d250: hsl(var(--orange-hue), var(--orange-saturation), calc(var(--orange-lightness) - 25%))

  --yellow-hue: 35
  --yellow-saturation: 55%
  --yellow-lightness: 55%
  --yellow: hsl(var(--yellow-hue), var(--yellow-saturation), var(--yellow-lightness))
  --yellow-d: hsl(var(--yellow-hue), var(--yellow-saturation), calc(var(--yellow-lightness) - 25%))

  --green-hue: 115
  --green-saturation: 28%
  --green-lightness: 52%
  --green: hsl(var(--green-hue), var(--green-saturation), var(--green-lightness))
  --green-d: hsl(var(--green-hue), var(--green-saturation), calc(var(--green-lightness) - 25%))

  --text-hue: var(--grey-hue)
  --text-saturation: var(--grey-saturation)
  --text-lightness: var(--800)
  --text: hsl(var(--text-hue), var(--text-saturation), var(--text-lightness))

  --text-dark-hue: var(--grey-hue)
  --text-dark-saturation: var(--grey-saturation)
  --text-dark-lightness: var(--800)
  --text-colour-dark: hsl(var(--text-dark-hue), var(--text-dark-saturation), var(--text-dark-lightness))

  --text-mid2-hue: var(--grey-hue)
  --text-mid2-saturation: var(--grey-saturation)
  --text-mid2-lightness: var(--700)
  --text-mid2: hsl(var(--text-mid2-hue), var(--text-mid2-saturation), var(--text-mid2-lightness))

  --text-mid-hue: var(--grey-hue)
  --text-mid-saturation: var(--grey-saturation)
  --text-mid-lightness: var(--600)
  --text-mid: hsl(var(--text-mid-hue), var(--text-mid-saturation), var(--text-mid-lightness))

  --text-light-hue: var(--grey-hue)
  --text-light-saturation: var(--grey-saturation)
  --text-light-lightness: var(--300)
  --text-light: hsl(var(--text-light-hue), var(--text-light-saturation), var(--text-light-lightness))

  --text-disabled-hue: var(--grey-hue)
  --text-disabled-saturation: var(--grey-saturation)
  --text-disabled-lightness: var(--500)
  --text-disabled: hsl(var(--text-disabled-hue), var(--text-disabled-saturation), var(--text-disabled-lightness))

  --text-headings: var(--text)

  --primary-100-hex: #034c8c
  --primary-hex: hsl(208, 96%, 38%)
  --primary-l200-hex: #2d9bfb
  --primary-hue: 208
  --primary-saturation: 96%
  --primary-lightness: 38%
  --primary: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-lightness))
  --primary-hsl: var(--primary-hue), var(--primary-saturation), var(--primary-lightness)

  --primary-d050-lightness: calc(var(--primary-lightness) - 5%)
  --primary-d100-lightness: calc(var(--primary-lightness) - 10%)
  --primary-d150-lightness: calc(var(--primary-lightness) - 15%)
  --primary-d200-lightness: calc(var(--primary-lightness) - 20%)
  --primary-d250-lightness: calc(var(--primary-lightness) - 25%)
  --primary-d300-lightness: calc(var(--primary-lightness) - 30%)
  --primary-d400-lightness: calc(var(--primary-lightness) - 40%)

  --primary-d050: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-d050-lightness))
  --primary-d100: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-d100-lightness))
  --primary-d150: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-d150-lightness))
  --primary-d200: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-d200-lightness))
  --primary-d250: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-d250-lightness))
  --primary-d300: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-d300-lightness))
  --primary-d400: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-d400-lightness))
  --primary-l100: hsl(var(--primary-hue), var(--primary-saturation), calc(var(--primary-lightness) + 10%))
  --primary-l150: hsl(var(--primary-hue), var(--primary-saturation), calc(var(--primary-lightness) + 15%))
  --primary-l200: hsl(var(--primary-hue), var(--primary-saturation), calc(var(--primary-lightness) + 20%))
  --primary-l300: hsl(var(--primary-hue), var(--primary-saturation), calc(var(--primary-lightness) + 30%))
  --primary-l375: hsl(var(--primary-hue), var(--primary-saturation), calc(var(--primary-lightness) + 37.5%))
  --primary-l400: hsl(var(--primary-hue), var(--primary-saturation), calc(var(--primary-lightness) + 40%))
  --primary-l500: hsl(var(--primary-hue), var(--primary-saturation), calc(var(--primary-lightness) + 50%))

  --primary-trans-5: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-lightness), 0.05)
  --primary-trans-10: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-lightness), 0.10)
  --primary-trans-15: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-lightness), 0.15)
  --primary-trans-25: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-lightness), 0.25)
  --primary-trans-30: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-lightness), 0.30)
  --primary-trans-50: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-lightness), 0.50)
  --primary-d400-trans-10: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-d400-lightness), 0.1)

  --accent-hex: var(--primary-hex)
  --accent-hue: var(--primary-hue)
  --accent-saturation: var(--primary-saturation)
  --accent-lightness: var(--primary-lightness)
  --accent: hsl(var(--accent-hue), var(--accent-saturation), var(--accent-lightness))
  --accent-d050: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) - 5%))
  --accent-d100: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) - 10%))
  --accent-d200: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) - 20%))
  --accent-d300: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) - 30%))
  --accent-d400: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) - 40%))
  --accent-l100: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) + 10%))
  --accent-l200: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) + 20%))
  --accent-l300: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) + 30%))
  --accent-l400: hsl(var(--accent-hue), var(--accent-saturation), calc(var(--accent-lightness) + 40%))

  --accent-trans-800: hsla(var(--accent-hue), var(--accent-saturation), var(--accent-lightness), 0.8)
  --accent-trans-700: hsla(var(--accent-hue), var(--accent-saturation), var(--accent-lightness), 0.7)
  --accent-trans-600: hsla(var(--accent-hue), var(--accent-saturation), var(--accent-lightness), 0.6)
  --accent-trans-200: hsla(var(--accent-hue), var(--accent-saturation), var(--accent-lightness), 0.2)
  --accent-trans-100: hsla(var(--accent-hue), var(--accent-saturation), var(--accent-lightness), 0.1)
  --accent-trans-050: hsla(var(--accent-hue), var(--accent-saturation), var(--accent-lightness), 0.05)
  --accent-trans-026: hsla(var(--accent-hue), var(--accent-saturation), var(--accent-lightness), 0.026)

  --accent-2-hue: var(--warning-hue)
  --accent-2-saturation: var(--warning-saturation)
  --accent-2-lightness: var(--warning-lightness)
  --accent-2: hsl(var(--accent-2-hue), var(--accent-2-saturation), var(--accent-2-lightness))

  --accent-icon-colour: var(--white)

  --secondary-hex: #021859
  --secondary-hue: var(--grey-hue)
  --secondary-saturation: var(--grey-saturation)
  --secondary-lightness: var(--800)
  --secondary-hsl: var(--secondary-hue), var(--secondary-saturation), var(--secondary-lightness)
  --secondary: hsl(var(--secondary-hue), var(--secondary-saturation), var(--secondary-lightness))
  --secondary-d050: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) - 5%))
  --secondary-d100: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) - 10%))
  --secondary-d150: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) - 15%))
  --secondary-d200: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) - 20%))
  --secondary-d300: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) - 30%))
  --secondary-d400: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) - 40%))
  --secondary-l100: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) + 10%))
  --secondary-l200: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) + 20%))
  --secondary-l300: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) + 30%))
  --secondary-l400: hsl(var(--secondary-hue), var(--secondary-saturation), calc(var(--secondary-lightness) + 40%))

  --secondary-trans-20: hsla(var(--secondary-hue), var(--secondary-saturation), var(--secondary-lightness), 0.2)
  --secondary-trans-30: hsla(var(--secondary-hue), var(--secondary-saturation), var(--secondary-lightness), 0.3)

  --success-hue: var(--green-hue)
  --success-saturation: var(--green-saturation)
  --success-lightness: var(--green-lightness)
  --success: hsl(var(--success-hue), var(--success-saturation), var(--success-lightness))
  --success-d050: hsl(var(--success-hue), var(--success-saturation), calc(var(--success-lightness) - 5%))
  --success-d250: hsl(var(--success-hue), var(--success-saturation), calc(var(--success-lightness) - 25%))
  --success-d300: hsl(var(--success-hue), var(--success-saturation), calc(var(--success-lightness) - 30%))

  --info: var(--text-light)

  --warning-hue: var(--yellow-hue)
  --warning-saturation: var(--yellow-saturation)
  --warning-lightness: var(--yellow-lightness)
  --warning: hsl(var(--warning-hue), var(--warning-saturation), var(--warning-lightness))
  --warning-d050: hsl(var(--warning-hue), var(--warning-saturation), calc(var(--warning-lightness) - 5%))
  --warning-d100: hsl(var(--warning-hue), var(--warning-saturation), calc(var(--warning-lightness) - 10%))
  --warning-d250: hsl(var(--warning-hue), var(--warning-saturation), calc(var(--warning-lightness) - 25%))
  --warning-d300: hsl(var(--warning-hue), var(--warning-saturation), calc(var(--warning-lightness) - 30%))
  --warning-l400: hsl(var(--warning-hue), var(--warning-saturation), calc(var(--warning-lightness) + 40%))

  --danger-hue: var(--red-hue)
  --danger-saturation: var(--red-saturation)
  --danger-lightness: var(--red-lightness)
  --danger: hsl(var(--danger-hue), var(--danger-saturation), var(--danger-lightness))
  --danger-d050: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) - 5%))
  --danger-d100: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) - 10%))
  --danger-d150: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) - 15%))
  --danger-d200: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) - 20%))
  --danger-d300: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) - 30%))

  --danger-l430: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) + 43%))
  --danger-l350: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) + 35%))
  --danger-l300: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) + 30%))
  --danger-l200: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) + 20%))
  --danger-l150: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) + 15%))
  --danger-l100: hsl(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) + 10%))

  --danger-trans-100: hsla(var(--danger-hue), var(--danger-saturation), var(--danger-lightness), 0.1)
  --danger-d400-trans-100: hsla(var(--danger-hue), var(--danger-saturation), calc(var(--danger-lightness) - 40%), 0.1)

  --text-coloured-colour: var(--primary-d100)
  --modal-subtitle-txt-colour: var(--secondary)

  --bg-1-hue: var(--primary-hue)
  --bg-1-saturation: 20%
  --bg-1-lightness: 93%
  --bg-1: hsl(var(--bg-1-hue), var(--bg-1-saturation), var(--bg-1-lightness))
  --bg-2-hue: calc(var(--primary-hue) - 25)
  --bg-2-saturation: 25%
  --bg-2-lightness: 95%
  --bg-2: hsl(var(--bg-2-hue), var(--bg-2-saturation), var(--bg-2-lightness))

  --scrollbar-colour: auto
  --scrollbar-colour--hover: auto

  /* NAVIGATION */
  --nav-bg-1: var(--primary)
  --nav-bg-2: var(--secondary)
  --nav-icon-colour: var(--secondary)
  --nav-icon-colour-active: var(--secondary-d100)
  --nav-tasks-indicator-bg: var(--primary)
  --nav-tasks-indicator-text: var(--white)
  --nav-profile-icon-colour: var(--secondary)
  --nav-profile-btn-colour: var(--primary)
  --nav-breadcrumb: var(--secondary)
  --nav-breadcrumb-link: var(--grey-600)
  /* BACKGROUND */
  --bg-gradient: linear-gradient(to bottom right, var(--bg-1), var(--bg-2))
  // overlay background colour i.e the colour of the translucent colour the overlay sits on to obscure the app behind it
  --overlay-color: hsla(var(--primary-hue), var(--primary-saturation), calc(var(--primary-lightness) - 30%), 0.8)

  --border-color-hue: var(--grey-hue)
  --border-color-saturation: var(--grey-saturation)
  --border-color-lightness: var(--300)
  --border-color: hsla(var(--border-color-hue), var(--border-color-saturation), var(--border-color-lightness))
  --border-color--light: var(--grey-150)
  --border-color-focus-grey: hsla(var(--border-color-hue), var(--border-color-saturation), var(--500))
  --border-color-focus: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-lightness))

  --rule-grid-dropdown: hsl(var(--dropdown-bg-hue), var(--dropdown-bg-saturation), calc(var(--dropdown-bg-lightness) + 5%))
  --rule-grid-dropdown-option-border-colour: hsla(var(--border-color-hue), var(--border-color-saturation), calc(var(--border-color-lightness) + 10%))
  --rule-grid-date-picker-bg-colour: var(--rule-grid-dropdown)

  --shadow-hue: var(--primary-hue)
  --shadow-saturation: 10%
  --shadow-lightness: var(--primary-lightness)
  --shadow-color-100: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.1)
  --shadow-color-200: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.2)
  --shadow-color-300: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.3)
  --shadow-color-400: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.4)
  --shadow-color-500: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.5)
  --shadow-color-600: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.6)
  --shadow-color-700: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.7)
  --shadow-color-800: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.8)
  --shadow-color-900: hsla(var(--shadow-hue), var(--shadow-saturation), var(--shadow-lightness), 0.9)

  --highlight-color-100: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.1)
  --highlight-color-200: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.2)
  --highlight-color-300: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.3)
  --highlight-color-400: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.4)
  --highlight-color-500: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.5)
  --highlight-color-600: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.6)
  --highlight-color-700: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.7)
  --highlight-color-800: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.8)
  --highlight-color-900: hsla(var(--primary-hue), var(--shadow-saturation), 100%, 0.9)

  --grey-trans-025: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.025)
  --grey-trans-035: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.035)
  --grey-trans-050: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.05)
  --grey-trans-100: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.1)
  --grey-trans-150: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.15)
  --grey-trans-200: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.2)
  --grey-trans-250: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.25)
  --grey-trans-300: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.3)
  --grey-trans-400: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.4)
  --grey-trans-500: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.5)
  --grey-trans-700: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.7)
  --grey-trans-800: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.8)
  --grey-trans-850: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.85)
  --grey-trans-875: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.875)
  --grey-trans-900: hsla(var(--grey-hue), var(--grey-saturation), 0%, 0.9)

  --white-trans-050: hsla(0, 0%, 100%, 0.05)
  --white-trans-100: hsla(0, 0%, 100%, 0.1)
  --white-trans-150: hsla(0, 0%, 100%, 0.15)
  --white-trans-200: hsla(0, 0%, 100%, 0.2)
  --white-trans-250: hsla(0, 0%, 100%, 0.25)
  --white-trans-300: hsla(0, 0%, 100%, 0.3)
  --white-trans-400: hsla(0, 0%, 100%, 0.4)
  --white-trans-500: hsla(0, 0%, 100%, 0.5)
  --white-trans-700: hsla(0, 0%, 100%, 0.7)
  --white-trans-800: hsla(0, 0%, 100%, 0.8)
  --white-trans-900: hsla(0, 0%, 100%, 0.9)

  --grey-200-trans-850: hsla(var(--grey-hue), var(--grey-saturation), var(--200), 0.85)
  --grey-200-trans-900: hsla(var(--grey-hue), var(--grey-saturation), var(--200), 0.9)

  --collapsed-button-bg-colour-hue: var(--accent-hue)
  --collapsed-button-bg-colour-saturation: var(--accent-saturation)
  --collapsed-button-bg-colour-lightness: var(--accent-lightness)
  --collapsed-button-bg-colour: hsl(var(--collapsed-button-bg-colour-hue), var(--collapsed-button-bg-colour-saturation), var(--collapsed-button-bg-colour-lightness))
  --collapsed-button-bg-colour--hover: hsl(var(--collapsed-button-bg-colour-hue), var(--collapsed-button-bg-colour-saturation), calc(var(--collapsed-button-bg-colour-lightness) - 5%))
  --collapse-button-text-colour: var(--white)
  --collapse-button-text-colour--invalid: var(--white)
  --grid-indicator-icon-colour: var(--primary)

  --btn-icon-focus-surround-colour: rgb(0 0 0 / 10%)
  --btn-icon-hover-surround-colour: rgb(255 255 255 / 5%)

  --link-text-hue: var(--grey-hue)
  --link-text-saturation: var(--grey-saturation)
  --link-text-lightness: var(--800)
  --link-text-colour: hsl(var(--link-text-hue), var(--link-text-saturation), var(--link-text-lightness))
  --link-text-decoration-colour: hsl(var(--link-text-hue), var(--link-text-saturation), calc(var(--link-text-lightness) + 40%))
  --link-text-decoration-colour--hover: var(--link-text-colour)
  --link-active-text-colour: hsla(var(--text-hue), var(--text-saturation), calc(var(--text-lightness) - 10%))

  --link-alt-text-hue: var(--grey-hue)
  --link-alt-text-saturation: var(--grey-saturation)
  --link-alt-text-lightness: var(--800)
  --link-alt-text-colour: hsl(var(--link-alt-text-hue), var(--link-alt-text-saturation), var(--link-alt-text-lightness))
  --link-alt-text-decoration-colour: hsl(var(--link-alt-text-hue), var(--link-alt-text-saturation), calc(var(--link-alt-text-lightness) + 40%))
  --link-alt-text-decoration-colour--hover: var(--link-alt-text-colour)
  --link-alt-active-text-colour: hsla(var(--text-hue), var(--text-saturation), calc(var(--text-lightness) - 10%))

  --button-text-colour: var(--text)
  --button-muted-text: var(--text-mid2)
  --button-light-bg--hue: var(--grey-hue)
  --button-light-bg--saturation: var(--grey-saturation)
  --button-light-bg--lightness: var(--025)

  --button-light-bg: hsl(var(--button-light-bg--hue), var(--button-light-bg--saturation), var(--button-light-bg--lightness))
  --button-light-bg--hover: hsl(var(--button-light-bg--hue), var(--button-light-bg--saturation), calc(var(--button-light-bg--lightness) - 5%))
  --button-light-border-colour: hsl(var(--button-light-bg--hue), var(--button-light-bg--saturation), calc(var(--button-light-bg--lightness) - 12.5%))
  --button-light-border-colour--hover: hsl(var(--button-light-bg--hue), var(--button-light-bg--saturation), calc(var(--button-light-bg--lightness) - 20%))

  --button-alt-bg--hue: var(--grey-hue)
  --button-alt-bg--saturation: var(--grey-saturation)
  --button-alt-bg--lightness: var(--025)
  --button-alt-bg-colour: hsl(var(--button-alt-bg--hue), var(--button-alt-bg--saturation), var(--button-alt-bg--lightness))
  --button-alt-bg-colour--hover: hsl(var(--button-alt-bg--hue), var(--button-alt-bg--saturation), calc(var(--button-light-bg--lightness) - 7.5%))

  --button-on-dark-hue: var(--button-light-bg--hue)
  --button-on-dark-saturation: var(--button-light-bg--saturation)
  --button-on-dark-lightness: var(--button-light-bg--lightness)

  --button-on-dark-bg-colour: hsl(var(--button-on-dark-hue), var(--button-on-dark-saturation), var(--button-on-dark-lightness))
  --button-on-dark-bg-colour--hover: hsl(var(--button-on-dark-hue), var(--button-on-dark-saturation), calc(var(--button-on-dark-lightness) - 5%))
  --button-on-dark-border-colour: hsl(var(--button-on-dark-hue), var(--button-on-dark-saturation), calc(var(--button-on-dark-lightness) - 12.5%))
  --button-on-dark-border-colour--hover: hsl(var(--button-on-dark-hue), var(--button-on-dark-saturation), calc(var(--button-on-dark-lightness) - 20%))
  --button-on-dark-text-colour: var(--button-text-colour)
  --button-on-dark-icon-colour: var(--primary)

  --modal-button-bg-colour: var(--primary)
  --modal-button-bg-colour--hover: var(--primary-d050)

  --input-text-colour: var(--text)
  --input-bg-colour: var(--white)
  --input-border-colour: var(--border-color--light)
  --input-disabled: var(--grey-500)
  --input-text-placeholder-text-colour: var(--text-mid2)

  --dropdown-bg-hue: var(--grey-hue)
  --dropdown-bg-saturation: var(--grey-saturation)
  --dropdown-bg-lightness: var(--025)
  --dropdown-bg-colour: hsl(var(--dropdown-bg-hue), var(--dropdown-bg-saturation), var(--dropdown-bg-lightness))
  --dropdown-option-border-colour: var(--border-color)
  --dropdown-option-bg-highlight-colour: var(--grey-150)
  --dropdown-option-bg-highlight-colour-l: var(--grey-050)
  --dropdown-default-option-bg-colour: var(--grey-150)

  --unread-task-bg-colour: var(--primary-trans-10)
  --task-button-text-colour: var(--button-text-colour)
  --task-button-highlight-colour: var(--grey-trans-035)

  --dropdown-menu-bg-colour: var(--white)
  --menu-child-btn-text-colour: var(--button-text-colour)
  --dropdown-menu-child-bg-colour--hover: var(--grey-025)
  --dropdown-menu-child-bg-colour--focus: var(--grey-050)
  --dropdown-menu-child-bg-colour--disabled: var(--grey-75)

  --date-picker-bg-colour: hsl(var(--dropdown-bg-hue), var(--dropdown-bg-saturation), calc(var(--dropdown-bg-lightness) + 5%))
  --time-picker-bg-colour: hsl(var(--dropdown-bg-hue), var(--dropdown-bg-saturation), calc(var(--dropdown-bg-lightness) + 10%))

  --litepicker-accent-colour-hue: var(--primary-hue)
  --litepicker-accent-colour-saturation: var(--primary-saturation)
  --litepicker-accent-colour-lightness: var(--primary-lightness)
  --litepicker-accent-colour: hsl(var(--litepicker-accent-colour-hue), var(--litepicker-accent-colour-saturation), var(--litepicker-accent-colour-lightness))
  --litepicker-accent-colour-d100: hsl(var(--litepicker-accent-colour-hue), var(--litepicker-accent-colour-saturation), calc(var(--litepicker-accent-colour-lightness) - 10%))
  --litepicker-todays-colour: var(--danger-d050)

  --search-hue: var(--grey-hue)
  --search-saturation: var(--grey-saturation)
  --search-lightness: var(--grey-lightness)
  --search-input-icon-colour: hsl(var(--grey-hue), var(--grey-saturation), var(--grey-lightness))
  --search-input-icon-colour--hover: hsl(var(--grey-hue), var(--grey-saturation), calc(var(--grey-lightness) - 5%))

  --highlight-option-colour: var(--primary-l500)

  --section-container-colour: hsla(var(--secondary-hsl), 0.1)
  --section-container-colour-inner: var(--section-container-colour)
  --section-container-colour--import: var(--white-trans-050)
  --group-container-bg-colour: var(--section-container-colour-inner)
  --reports-section-container-colour-inner: hsla(208, 96%, calc(38% - 15%), .1)
  --radio-options-container-colour: var(--light)

  --bar-colour: var(--white)
  --bar-body-colour: var(--light)
  --bar-icon-colour: var(--primary)
  --bar-border-colour: var(--grey-300)
  --bar-border-colour-l: var(--grey-150)
  --bar-text-colour: var(--text)

  --report-bar-border-colour: var(--bar-border-colour)
  --query-template-border-colour: var(--bar-border-colour)

  --audit-bar-bg-colour: var(--bar-colour)

  --table-card-bg: var(--secondary)
  --table-card-th: var(--primary)
  --table-card-th-alt: var(--grey-150)
  --table-card-row-odd: var(--white)
  --table-card-row-even: var(--grey-050)
  --table-card-row-inner: var(--grey-075)
  --table-header-text-colour: var(--text)
  --table-header-border-colour: var(--grey-500)

  --empty-table-bg-colour: var(--table-card-row-odd)
  --empty-table-bg-colour--alt: var(--table-card-row-even)
  --empty-table-text-colour: var(--primary-d200)

  --modal-border-colour: transparent
  --card-body-bg-colour: var(--grey-150)
  --card-body-bg-colour--alt: var(--white)
  --card-body-bg-colour--light: var(--vlight)
  --card-header-footer-bg-colour: var(--vlight)
  --card-header-text-colour: var(--text)
  --card-header-boder-colour--alt: var(--border-color--light)

  --profile-card-query-box-toolbar-bg-colour: var(--secondary)
  --profile-card-query-box-body-bg-colour: var(--query-body-bg-colour)
  --profile-card-query-toolbar-btn-bg-colour: var(--light)
  --profile-card-query-toolbar-btn-border-colour: var(--grey-300)
  --profile-card-query-toolbar-btn-colour: var(--secondary)

  --query-toolbar-btn-bg-colour: var(--primary)
  --query-toolbar-btn-border-colour: var(--primary-d050)
  --query-toolbar-btn-colour: var(--white)
  --query-toolbar-link-colour: var(--light)
  --query-toolbar-link-hover-bg-colour: hsla(var(--grey-hue), var(--grey-saturation), var(--200), 0.1)
  --query-toolbar-link-text-decoration-colour: hsla(var(--grey-hue), var(--grey-saturation), var(--200), 0.4)
  --query-toolbar-link-hover-text-decoration-colour: var(--light)

  --filter-group-inner-colour: var(--shadow-color-100)
  --no-filter-bg-colour: var(--grey-100)
  --filter-modal-bg-colour: var(--white)

  --query-toolbar-bg-colour: var(--grey-800)
  --query-body-bg-colour: var(--white)
  --query-advanced-bg-colour: var(--grey-650)
  --query-priority-section-colour: var(--grey-600)

  /* GRID OVERRIDES */
  --rule-grid-border-colour: transparent
  --rule-grid-background-colour: var(--white)
  --rule-grid-header-background-color-hue: var(--grey-hue)
  --rule-grid-header-background-color-saturation: var(--grey-saturation)
  --rule-grid-header-background-color-lightness: var(--025)
  --rule-grid-header-background-color: hsl(var(--rule-grid-header-background-color-hue), var(--rule-grid-header-background-color-saturation), var(--rule-grid-header-background-color-lightness))
  --rule-grid-header-cell-background-color--hover: hsl(var(--rule-grid-header-background-color-hue), var(--rule-grid-header-background-color-saturation), calc(var(--rule-grid-header-background-color-lightness) - 2.5%))
  --rule-grid-row-header-border-colour: hsl(var(--rule-grid-header-background-color-hue), var(--rule-grid-header-background-color-saturation), calc(var(--rule-grid-header-background-color-lightness) - 12.5%))
  --rule-cell-border-colour: hsla(var(--rule-grid-header-background-color-hue), var(--rule-grid-header-background-color-saturation), calc(var(--rule-grid-header-background-color-lightness) - 20%), 0.5)
  --rule-grid-even-row-background-hue: 0
  --rule-grid-even-row-background-saturation: 0%
  --rule-grid-even-row-background-lightness: 100%
  --rule-grid-even-row-background-color: hsl(var(--rule-grid-even-row-background-hue), var(--rule-grid-even-row-background-saturation), var(--rule-grid-even-row-background-lightness))
  --rule-grid-odd-row-background-color: hsl(var(--rule-grid-even-row-background-hue), var(--rule-grid-even-row-background-saturation), calc(var(--rule-grid-even-row-background-lightness) - 1.5%))
  --rule-grid-row-color--hover: var(--grey-trans-025)
  --rule-grid-row-header-colour--hover: var(--rule-grid-row-color--hover)
  --rule-grid-status-bar-border-colour: var(--grey-150)
  --rule-grid-control-panel-bg-colour: var(--white)
  --rule-secondary-foreground-colour: var(--primary)
  --rule-secondary-foreground-colour-selected: var(--rule-secondary-foreground-colour)
  --rule-grid-menu-option-active-colour: var(--rule-grid-row-color--hover)
  --rule-grid-menu-bg-colour: var(--rule-grid-background-colour)
  --rule-grid-pinned-border-colour: var(--border-color)
  --rule-grid-cell-bg-colour--error: var(--danger-l300)
  --rule-grid-cell-text-colour--error: var(--danger-d200)
  --rule-grid-selected-tab-underline-colour: var(--accent)
  --rule-grid-value-change-value-highlight-background-color: var(--accent)


  --rule-grid-selection-border-color-hue: var(--accent-hue)
  --rule-grid-selection-border-color-saturation: var(--accent-saturation)
  --rule-grid-selection-border-color-lightness: var(--accent-lightness)
  --rule-grid-selection-border-color: hsl(var(--rule-grid-selection-border-color-hue), var(--rule-grid-selection-border-color-saturation), var(--rule-grid-selection-border-color-lightness))
  --rule-grid-selection-bg-color : hsla(var(--rule-grid-selection-border-color-hue), var(--rule-grid-selection-border-color-saturation), var(--rule-grid-selection-border-color-lightness), 0.1)
  --rule-grid-selection-highlight-color : var(--rule-grid-selection-bg-color)
  --rule-grid-selection-bg-color-1 : hsla(var(--rule-grid-selection-border-color-hue), var(--rule-grid-selection-border-color-saturation), var(--rule-grid-selection-border-color-lightness), 0.026)
  --rule-grid-selection-bg-color-2 : hsla(var(--rule-grid-selection-border-color-hue), var(--rule-grid-selection-border-color-saturation), var(--rule-grid-selection-border-color-lightness), 0.50)
  --rule-grid-selection-bg-color-3 : hsla(var(--rule-grid-selection-border-color-hue), var(--rule-grid-selection-border-color-saturation), var(--rule-grid-selection-border-color-lightness), 0.1)
  --rule-grid-selection-bg-color-4 : hsla(var(--rule-grid-selection-border-color-hue), var(--rule-grid-selection-border-color-saturation), var(--rule-grid-selection-border-color-lightness), 0.2)


  --readonly-cell-text-color: var(--text-disabled)

  --grid-cell-icon-colour: var(--primary)
  --table-icon-standard: var(--grid-cell-icon-colour)
  --menu-icon-colour: var(--primary)
  --profile-detail-icon-colour: var(--primary)
  --scheduled-tasks-icon-colour: var(--primary)
  --criteria-button-icon-colour: var(--primary)
  --criteria-button-icon-colour--alt: var(--light)
  --criteria-button-icon-colour--hover: var(--primary-d100)
  --task-action-icon-colour: var(--primary)
  --modal-close-icon-colour: var(--danger)
  --rule-grid-column-header-icon-colour: var(--primary-trans-30)

  --pagination-bar-bg-colour: var(--white)
  --pagination-bar-text-colour: var(--grey-500)
  --pagination-bar-accent-colour: var(--primary)
  --pagination-bar-btn-colour: var(--pagination-bar-accent-colour)
  --pagination-bar-current-page-colour: var(--pagination-bar-accent-colour)
  --pagination-bar-btn-colour--disabled: var(--disabled-grey)

  --notification-bar-bg-colour: var(--white)
  --notification-close-button-bg-colour: var(--grey-100)
  --notification-close-button-bg-colour--hover: var(--grey-150)
  --notification-close-button-text-colour: var(--grey-500)
  --notification-close-button-text-colour--hover: var(--grey-600)

  --drag-n-drop-bg-colour: var(--white)
  --drag-n-drop-bg-colour--dragover: var(--grey-200-trans-850)
  --drag-n-drop-bg-colour--dragover--emphasize: var(--grey-200-trans-900)
  --drag-n-drop-bg-colour--drop-text-colour: var(--grey-600)
  --drag-n-drop-bg-colour--drop-text-colour--grow: var(--grey-700)
  --drag-n-drop-bg-colour--drop-text-colour--emphasize: var(--grey-700)
  --drag-n-drop-border-colour: var(--grey-trans-100)
  --drag-n-drop-border-colour--drag: var(--border-color)
  --drag-n-drop-border-colour--emphasize: var(--primary)
  --drag-border-color: var(--grey-300)
  --drag-border-color--hover: var(--grey-500)
  --drag-n-drop-icon-colour: var(--grey-trans-100)
  --drag-n-drop-icon-colour--emphasize: var(--grey-trans-200)
  --drag-n-drop-surround-bg-colour: var(--bg-1)

  --side-panel-component-bg-colour: var(--white)
  --file-info-bg-colour: var(--grey-800)
  --file-info-icon-colour: var(--grey-300)
  --file-info-toolbar-bg-colour: var(--side-panel-component-bg-colour)
  --image-viewer-bg-colour: var(--file-info-bg-colour)
  --asset-side-panel-bg-colour: var(--grey-700)
  --file-history-version-bar-bg-colour: var(--grey-150)
  --file-history-version-bar-bg-colour--hover: var(--grey-100)
  --file-history-version-bar-bg-colour--active: var(--white)
  --file-history-version-bar-download-icon-colour: var(--primary)
  --tooltip-bg-colour: var(--white)

  --error-list-bg-colour: var(--white)
  --status-bar-bg-colour: var(--vlight)

  --progress-ring-back-colour: var(--grey-250)
  --progress-ring-front-colour: var(--primary)
  --progress-ring-front-colour--success: var(--success)

  --text-emphasis-colour: var(--primary)

  --error-text-light: var(--text-light)

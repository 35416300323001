@import 'positioning-mixins'

%icon-hover-states
  &.icon-danger
    color: var(--danger-d100)
  &.icon-grey
    color: var(--grey-700)
  &.icon-grey-l
    color: var(--grey-300)
  &.icon-grey-d
    color: var(--grey-700)
  &.icon-primary
    color: var(--primary-d200)
  &.icon-accent
    color: var(--accent-d200)
  &.icon-secondary
    color: var(--secondary-d200)
  &.icon-warning
    color: var(--warning-d250)
  &.icon-light
    color: var(--grey-300)
  &.filter-link-icon
    color: var(--criteria-button-icon-colour--hover)

=icon
  display: inline-block
  justify-self: center
  align-self: center
  width: max-content
  line-height: 1
  &.icon-bg
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 0
  &.left
    margin-right: var(--blh-50)
  &.right
    margin-left: var(--blh-50)
  &.left-xs
    margin-right: var(--blh-25)
  &.right-xs
    margin-left: var(--blh-25)
  &.icon-faded
    opacity: 25%
  &.icon-white
    color: var(--white)
  &.icon-grey
    color: var(--grey-600)
  &.icon-grey-150
    color: var(--grey-150)
  &.icon-grey-200
    color: var(--grey-200)
  &.icon-grey-m
    color: var(--grey-400)
  &.icon-grey-d
    color: var(--grey-600)
  &.icon-grey-l
    color: var(--grey-200)
  &.icon-primary
    color: var(--primary)
  &.icon-primary-d
    color: var(--primary-d200)
  &.icon-info
    color: var(--primary-l100)
  &.icon-accent
    color: var(--accent)
  &.icon-accent-d
    color: var(--accent-d200)
  &.icon-accent-2
    color: var(--accent-2)
  &.icon-secondary
    color: var(--secondary)
  &.icon-secondary-d
    color: var(--secondary-d200)
  &.icon-success
    color: var(--success)
  &.icon-success-d
    color: var(--success-d250)
  &.icon-danger
    color: var(--danger)
  &.icon-danger-d
    color: var(--danger-d100)
  &.icon-warning
    color: var(--warning)
  &.icon-warning-d
    color: var(--warning-d250)
  &.icon-light
    color: var(--light)
  &.disabled,
  &.disabled:hover
    color: var(--grey-150) !important
  &.icon-clickable
    cursor: pointer
    transition: var(--transition)
    &:hover:not(.icon-faded)
      @extend %icon-hover-states
    &:hover.icon-faded
      opacity: 100%
  &.icon-xs
    font-size: var(--xs)
  &.icon-sm
    font-size: var(--sm)
  &.icon-m
    font-size: var(--m)
  &.icon-m1
    font-size: var(--m2)
  &.icon-m2
    font-size: calc(var(--m2) + 5px)
  &.icon-l
    font-size: var(--l)
  &.icon-xl
    font-size: var(--xl)
  &.icon-xxl
    font-size: var(--xxl)
  &.icon-super
    font-size: var(--super)
  &.icon-super-xl
    font-size: var(--super-xl)
  &.icon-super-xxl
    font-size: var(--super-xxl)
  &.icon-opacity-50
    opacity: 0.5
  fa-icon
    display: grid

=disc($size: 10px, $bgColour: var(--primary), $colour: var(--white))
  +flex-cc
  height: $size
  width: $size
  color: $colour
  background-color: $bgColour
  border-radius: 50%

=indicator($size: 12px, $bgColour: var(--primary), $colour: var(--white), $top: -3px, $right: -3px, $bottom: 'auto', $left: 'auto', $fontSize: var(--xxs))
  +disc($size, $bgColour, $colour)
  position: absolute
  top: $top
  right: $right
  bottom: $bottom
  left: $left
  font-size: $fontSize
  letter-spacing: -0.5px
  z-index: 1

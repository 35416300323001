=border-variables
  --border-width: 1px
  --border-width-med: 2px
  --border-width-thick: 4px
  --border: var(--border-width) solid var(--border-color)
  --border-focused: var(--border-width) solid var(--border-color-focus)
  --border-danger: var(--border-width) solid var(--danger)
  --border-success: var(--border-width) solid var(--success)

  --border-solid-light: var(--border-width) solid var(--border-color--light)
  --border-solid-light-med: var(--border-width-med) solid var(--grey-150)
  --border-dashed-light: var(--border-width) dashed var(--grey-150)
  --border-dashed-light-med: var(--border-width-med) dashed var(--grey-150)
  --border-dashed-light-thick: var(--border-width-thick) dashed var(--grey-150)
  --border-dashed-mid-thick: var(--border-width-thick) dashed var(--grey-500)
  --border-dashed-trans-100-thick: var(--border-width-thick) dashed var(--grey-trans-100)

  --border-solid-d200: var(--border-width) solid var(--grey-200)
  --border-solid-d200-med: var(--border-width-med) solid var(--grey-200)

  --border-solid-dark: var(--border-width) solid var(--grey-300)
  --border-solid-dark-med: var(--border-width-med) solid var(--grey-300)
  --border-solid-dark-thick: var(--border-width-thick) solid var(--grey-300)
  --border-dashed-dark-thick: var(--border-width-thick) dashed var(--grey-300)

  --border-solid-d400: var(--border-width) solid var(--grey-400)
  --border-solid-d600: var(--border-width) solid var(--grey-600)
  --border-solid-d800: var(--border-width) solid var(--grey-800)

  --border-solid-danger: var(--border-width) solid var(--danger)
  --border-solid-danger-d150: var(--border-width) solid var(--danger-d150)

  --border-solid-primary: var(--border-width) solid var(--primary)
  --border-solid-primary-d200: var(--border-width) solid var(--primary-d200)
  --border-solid-primary-d400: var(--border-width) solid var(--primary-d300)
  --border-dashed-primary-thick: var(--border-width-thick) dashed var(--primary)

  --drag-n-drop-border: var(--border-width-thick) dashed var(--drag-n-drop-border-colour)
  --drag-n-drop-border--grow: var(--border-width-med) dashed var(--drag-n-drop-border-colour)

  --border-solid-transparent: var(--border-width) solid transparent
  --border-solid-shadow-100: var(--border-width) solid var(--shadow-color-100)

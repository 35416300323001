// LITEPICKER OVERRIDES
.litepicker
  display: flex !important
  font-family: var(--font-primary) !important
  font-size: var(--xs)
  .container__main
    flex-grow: 1 !important
  .container__months
    background-color: var(--date-picker-bg-colour)
    box-shadow: none !important
    border-radius: 0 !important
    flex-grow: 1 !important
    .month-item
      width: 100% !important
      display: flex
      flex-flow: column
    .month-item-weekdays-row,
    .container__days
      display: grid !important
      grid-template-columns: repeat(7, 1fr) !important
      grid-gap: 0 !important
    &.show-week-numbers
      .month-item-weekdays-row,
      .container__days
        grid-template-columns: repeat(8, 1fr) !important
    .container__days .day-item
      cursor: pointer
      width: auto !important
      height: auto !important
    .month-item-header
      .button-next-month,.button-previous-month
        cursor: pointer

@import 'mixins/button-mixins'

.btn-group
  +button-group
.btn
  +button
  +button-light
  +button-extenders
.muted-btn
  +button
  +button-muted
  +button-extenders
.primary-btn
  +button
  +button-primary
  +button-extenders
.primary-d-btn
  +button
  +button-primary-d
  +button-extenders
.accent-btn
  +button
  +button-extenders
.warning-btn
  +button
  +button-warning
  +button-extenders
.warning-d-btn
  +button
  +button-warning-d
  +button-extenders
.success-btn
  +button
  +button-success
  +button-extenders
.danger-btn
  +button
  +button-danger
  +button-extenders
.danger-d-btn
  +button
  +button-danger-d
  +button-extenders
.primary-btn-alt
  +button
  +button-primary-alt
  +button-extenders
.danger-btn-alt
  +button
  +button-danger-alt
  +button-extenders
.icon-btn
  +icon-button

.toolbar-btn
  +button(var(--sm), 0, false, true)
  +button-no-surround
  +button-extenders
  .icon,
  app-icon,
  app-icon2
    width: 1.1em
  fa-icon
    height: 1em
    width: 1em
  &:disabled,
  &.disabled
    fa-icon,
    .icon
      color: var(--accent-icon-colour)

.collapse-btn
  +button(var(--sm), var(--blh), false, false, none)
  +button-collapsed

.menu-child-btn
  +button(var(--sm), var(--blh) var(--blh), false, true, 0)
  +button-extenders
  background-color: transparent
  color: var(--menu-child-btn-text-colour)
  &:hover
    background: var(--dropdown-menu-child-bg-colour--hover)
  &:active,
  &:focus
    background: var(--dropdown-menu-child-bg-colour--focus)
  &:disabled
    background: var(--dropdown-menu-child-bg-colour--disabled)

.dropdown-toggle-btn
  +icon-button(var(--sm), 0, false, false)
  align-self: center
  justify-self: center

.modal-btn
  +button(var(--sm), var(--blh-25) var(--blh-200), false, true, false)
  +button-extenders
  margin-left: var(--blh)
  background-color: var(--modal-button-bg-colour)
  border: none
  color: var(--white)
  &:hover
    background-color: var(--modal-button-bg-colour--hover)

%title-framework
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-weight: var(--font-weight-bold)
  color: var(--text-headings)

%h1
  font-size: var(--xxl)
%h2
  font-size: var(--xl)
%h3
  font-size: var(--l)
%h4
  font-size: var(--m2)
%h5
  font-size: var(--m)
%h6
  font-size: var(--sm)

=h1
  @extend %h1
=h2
  @extend %h2
=h3
  @extend %h3
=h4
  @extend %h4
=h5
  @extend %h5
=h6
  @extend %h6

@import "@ag-grid-community/styles/ag-grid-no-native-widgets.css";
@import "@ag-grid-community/styles/ag-theme-alpine-no-font.css";

:root {
  /* GRID COLOURS /*
  /* NOTE: these need to be here rather than in the ag-theme scope so we can override it per client */

  --quick-column-hue: var(--secondary-hue);
  --quick-column-saturation: 40%;
  --quick-column-lightness: 40%;

  --readonly-hue: var(--secondary-hue);
  --readonly-saturation: 10%;
  --readonly-lightness: 90%;

  // Color to draw around selected cell ranges
  --range-selection-border-color: var(--rule-grid-selection-border-color);

  // Background colour of selected cell ranges. By default, setting this to a semi-transparent color (opacity of 0.1 to 0.5 works well) will generate appropriate values for the range-selection-background-color-{1..4} colours used when multiple ranges overlap.
  // NOTE: if setting this value to a CSS variable, and your app supports overlapping range selections, also set range-selection-background-color-{1..4}.
  --range-selection-background-color: var(--rule-grid-selection-bg-color);
  --right-click-selection-colour: var(--range-selection-background-color);

  // These 4 parameters are used for fine-grained control over the background color used when 1, 2, 3 or 4 ranges overlap.
  --range-selection-background-color-1: var(--rule-grid-selection-bg-color-1);
  --range-selection-background-color-2: var(--rule-grid-selection-bg-color-2);
  --range-selection-background-color-3: var(--rule-grid-selection-bg-color-3);
  --range-selection-background-color-4: var(--rule-grid-selection-bg-color-4);

  // Background colour to apply to a cell range when it is copied from or pasted into
  --range-selection-highlight-color: var(--rule-grid-selection-highlight-color);

  --readonly-cell-colour: hsla(var(--readonly-hue), var(--readonly-saturation), var(--readonly-lightness), 0.5);
  --readonly-header-cell-colour: hsla(var(--readonly-hue), var(--readonly-saturation), calc(var(--readonly-lightness) - 15%), 0.5);
  --readonly-header-cell-text-colour: var(--text-disabled);
  --readonly-header-cell-colour--hover: hsla(var(--readonly-hue), var(--readonly-saturation), calc(var(--readonly-lightness) - 20%), 0.5);

  --quick-column-cell-colour: hsla(var(--quick-column-hue), var(--quick-column-saturation), var(--quick-column-lightness), 0.5);
  --quick-column-header-cell-colour: hsla(var(--quick-column-hue), calc(var(--quick-column-saturation) + 10%), calc(var(--quick-column-lightness) - 15%), 0.5);
  --quick-column-header-cell-hover-colour: hsla(var(--quick-column-hue), calc(var(--quick-column-saturation) + 10%), calc(var(--quick-column-lightness) - 20%), 0.5);
}

.ag-theme-alpine {

  // Colour of text and icons in primary UI elements like menus
  --ag-foreground-color: var(--text);

  // Colour of text in grid cells
  --ag-data-color: var(--text);

  // Colour of text and icons in UI elements that need to be slightly less emphasised to avoid distracting attention from data
  --ag-secondary-foreground-color: var(--rule-secondary-foreground-colour);

  // Colour of text and icons in the header
  --ag-header-foreground-color: var(--text);

  // Color of elements that can't be interacted with because they are in a disabled state
  --ag-disabled-foreground-color: var(--text-light);

  // Background colour of the grid
  --ag-background-color: var(--rule-grid-background-colour);

  // Background colour for all headers, including the grid header, panels etc
  --ag-header-background-color: var(--rule-grid-header-background-color);

  // Background colour for second level headings within UI components
  --ag-subheader-background-color: deeppink; // Unused

  // Background colour for toolbars directly under subheadings (as used in the chart settings menu)
  --ag-subheader-toolbar-background-color: deeppink; // Unused

  // Background for areas of the interface that contain UI controls, like tool panels and the chart settings menu
  --ag-control-panel-background-color: var(--rule-grid-control-panel-bg-colour);

  // Background color of selected rows in the grid and in dropdown menus
  --ag-selected-row-background-color: var(--primary-trans-15);

  // Background color of the overlay shown over the grid when it is covered by an overlay, e.g. a data loading indicator.
  --ag-modal-overlay-background-color: var(--bg-1);

  // Background color when hovering over rows in the grid and in dropdown menus, or null for no rollover effect (note - if you want a rollover on one but not the other, set to null and use CSS to achieve the rollover)
  --ag-row-hover-color: var(--rule-grid-row-color--hover);

  // Color to draw around selected cell ranges
  --ag-range-selection-border-color: var(--range-selection-border-color);

  // Background colour of selected cell ranges. By default, setting this to a semi-transparent color (opacity of 0.1 to 0.5 works well) will generate appropriate values for the range-selection-background-color-{1..4} colours used when multiple ranges overlap.
  // NOTE: if setting this value to a CSS variable, and your app supports overlapping range selections, also set range-selection-background-color-{1..4}.
  --ag-range-selection-background-color: var(--range-selection-background-color);

  // These 4 parameters are used for fine-grained control over the background color used when 1, 2, 3 or 4 ranges overlap.
  --ag-range-selection-background-color-1: var(--range-selection-background-color-1);
  --ag-range-selection-background-color-2: var(--range-selection-background-color-2);
  --ag-range-selection-background-color-3: var(--range-selection-background-color-3);
  --ag-range-selection-background-color-4: var(--range-selection-background-color-4);

  // Background colour to apply to a cell range when it is copied from or pasted into
  --ag-range-selection-highlight-color: var(--range-selection-highlight-color);

  // Colour and thickness of the border drawn under selected tabs, including menus and tool panels
  --ag-selected-tab-underline-color: var(--rule-grid-selected-tab-underline-colour);
  --ag-selected-tab-underline-width: var(--blh-25);
  --ag-selected-tab-underline-transition-speed: 100ms;

  // Background colour for cells that provide categories to the current range chart
  --ag-range-selection-chart-category-background-color: rgba(#00FF84, 0.1);  // TODO

  // Background colour for cells that provide data to the current range chart
  --ag-range-selection-chart-background-color: rgba(#0058FF, 0.1);  // TODO

  // Rollover colour for header cells
  --ag-header-cell-hover-background-color: var(--rule-grid-header-cell-background-color--hover);

  // Colour applied to header cells when the column is being dragged to a new position
  --ag-header-cell-moving-background-color: var(--grey-050);

  // Colour to apply when a cell value changes and enableCellChangeFlash is enabled
  --ag-value-change-value-highlight-background-color: var(--rule-grid-value-change-value-highlight-background-color);

  // Colours to apply when a value increases or decreases in an agAnimateShowChangeCellRenderer cell
  --ag-value-change-delta-up-color: #43a047;
  --ag-value-change-delta-down-color: #e53935;

  // Colour for the "chip" that repersents a column that has been dragged onto a drop zone
  --ag-chip-background-color: var(--white);

  /** BORDERS **/

  // Draw borders around most UI elements
  --ag-borders: solid 1px;

  // Draw the few borders that are critical to UX, e.g. between headers and rows.
  //--ag-borders-critical: true;

  // Draw decorative borders separating UI elements within components
  //--ag-borders-secondary: true;

  // Draw borders around sidebar tabs so that the active tab appears connected to the current tool panel
  //--ag-borders-side-button: true;

  // Border radius applied to many elements such as dialogs and form widgets
  --ag-border-radius: var(--radius);

  // Colour for border around major UI components like the grid itself, headers, footers and tool panels
  --ag-border-color: var(--border-color);

  // Colour for borders used to separate elements within a major UI component
  --ag-secondary-border-color: var(--rule-cell-border-colour);

  // Colour of the border between grid rows, or null to display no border
  --ag-row-border-color: var(--danger);

  // Default border for cells. This can be used to specify the border-style and border-color properties e.g. `dashed red` but the border-width is fixed at 1px.
  --ag-cell-horizontal-border: solid var(--rule-cell-border-colour);

  // Separator between columns in the header. Displays between all header cells For best UX, use either this or header-column-resize-handle but not both
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: var(--rule-cell-border-colour);

  // Visible marker for resizeable columns. Displays in the same position as the column separator, but only when the column is resizeable. For best UX, use either this or header-column-separator but not both
  --ag-header-column-resize-handle-display: none;
  --ag-header-column-resize-handle-height: 50%;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: var(--rule-cell-border-colour);

  /** SIZING / PADDING / SPACING **/

  // grid-size is the main control for affecting how tightly data and UI elements are packed together. All padding and spacing in the grid is defined as a multiple of grid-size, so increasing it will make most components larger by increasing their internal white space while leaving the size of text and icons unchanged.
  --ag-grid-size: 8px;

  // The size of square icons and icon-buttons
  --ag-icon-size: 14px;

  // These 4 variables set the padding around and spacing between widgets in "widget containers" which are parts of the UI that contain many related widgets, like the set filter menu, charts settings tabs etc.
  --ag-widget-container-horizontal-padding: 12px;
  --ag-widget-container-vertical-padding: 12px;
  --ag-widget-horizontal-spacing: 12px;
  --ag-widget-vertical-spacing: var(--ag-grid-size);

  // Horizontal padding for grid and header cells (vertical padding is not set explicitly, but inferred from row-height / header-height
  --ag-cell-horizontal-padding: 24px;

  // Horizontal spacing between widgets inside cells (e.g. row group expand buttons and row selection checkboxes)
  --ag-cell-widget-spacing: var(--ag-cell-horizontal-padding);

  // Height of grid rows
  --ag-row-height: 49px;

  // Height of header rows
  --ag-header-height: var(--ag-row-height);

  // Height of items in lists (example of lists are dropdown select inputs and column menu set filters)
  --ag-list-item-height: 40px;

  // How much to indent child columns in the column tool panel relative to their parent
  --ag-column-select-indent-size: var(--ag-grid-size);

  // How much to indent child rows in the grid relative to their parent row
  --ag-row-group-indent-size: var(--ag-cell-widget-spacing);

  // How much to indent child columns in the filters tool panel relative to their parent
  --ag-filter-tool-panel-group-indent: 16px;

  /** FONTS **/
  --ag-font-family: var(--font-primary);
  --ag-font-size: 14px;

  // The name of the icon font family you're using
  --ag-icon-font-family: null;

  // cards are elements that float above the UI
  --ag-card-radius: var(--radius);

  // the default card shadow applies to simple cards like column drag indicators and text editors
  --ag-card-shadow: none;

  // override the shadow for popups - cards that contain complex UI, like menus and charts
  --ag-popup-shadow: var(--shadow);

  img, svg {
    max-width: unset;
    width: unset;
  }

  .ag-root-wrapper {
    border: solid 1px var(--rule-grid-border-colour);
    overflow: visible;
  }
  .ag-header {
    border-bottom-color: var(--rule-grid-row-header-border-colour);
  }

  .ag-header-cell.grid-column-readonly {
    background-color: var(--readonly-header-cell-colour);
    color: var(--readonly-header-cell-text-colour);
    &:hover {
      background-color: var(--readonly-header-cell-colour--hover) !important;
    }
  }
  .ag-header-cell.quick-column {
    background-color: var(--quick-column-header-cell-colour);
    color: white;
    .ag-header-icon {
      color: white;
    }
    fa-duotone-icon {
      --fa-primary-color: white;
    }
  }
  .ag-header-cell:not(.ag-column-resizing) + .quick-column.ag-header-cell:not(.ag-header-cell-moving):hover {
    background-color: var(--quick-column-header-cell-hover-colour);
  }
  .ag-dnd-ghost-icon {
    color: var(--primary);
  }
  .ag-theme-alpine.ag-dnd-ghost {
    color: var(--text);
  }
  .ag-menu-separator-part::after {
    border-top-color: var(--grey-200);
  }
  .ag-menu-column-select-wrapper {
    width: 350px;
  }
  .ag-cell-inline-editing {
    border-radius: 0;
  }
  input[class^="ag-"]:not([type]), input[class^="ag-"][type="text"], input[class^="ag-"][type="number"], input[class^="ag-"][type="tel"], input[class^="ag-"][type="date"], input[class^="ag-"][type="datetime-local"], textarea[class^="ag-"] {
    border-radius: 0;
    color: var(--button-text-colour);
  }
  .ag-tab {
    flex: none;
  }
  .ag-header-icon, .ag-tab {
    color: var(--rule-grid-column-header-icon-colour);
  }
  .ag-menu {
    background: var(--rule-grid-menu-bg-colour);
  }
  .ag-menu-option-active {
    background-color: var(--rule-grid-menu-option-active-colour);
  }
  .ag-menu-option-icon, .ag-tab-selected,  {
    color: var(--rule-secondary-foreground-colour-selected);
  }
  .ag-tab:hover, .ag-header-cell-menu-button:hover {
    color: var(--primary-d100);
  }
  .ag-popup-editor {
    border: var(--border);
    border-radius: var(--radius);
    overflow: hidden;
    > * {
      overflow: auto;
    }
  }

  .ag-menu-option {
    cursor: pointer;
  }

  // Our styles
  line-height: var(--sm);

  .grid-corner .ag-header-cell-comp-wrapper {
    height: 100%;
  }

  // Invalid (i.e. failed validation) cell styling
  .ag-cell.grid-cell-error {
    color: var(--rule-grid-cell-text-colour--error);
    background-color: var(--rule-grid-cell-bg-colour--error);
  }

  // Top left corner of the grid cell style
  .grid-corner {
    padding: 0; // Do the padding inside the cell renderer
  }

  // Row header cell style
  .grid-row-header {
    border-right-color: var(--rule-grid-row-header-border-colour) !important;
    background-color: var(--rule-grid-header-background-color) !important;
    outline: var(--rule-grid-row-header-border-colour) solid 1px !important;
    padding: 0; // Do the padding inside the cell renderer
  }

  .ag-header-row,
  .ag-row {
    border-color: var(--rule-cell-border-colour); // rgba(0, 0, 0, 0.1);
  }

  .ag-row-even {
    background-color: var(--rule-grid-even-row-background-color); // rgba(0, 0, 0, 0.1);
  }
  .ag-row-odd {
    background-color: var(--rule-grid-odd-row-background-color);
  }

  // Row header hover style
  .ag-row-hover {
    &::before {
      z-index: 1;
    }
    .grid-row-header {
      background-color: var(--rule-grid-row-header-colour--hover) !important;
    }
  }

  .ag-row.incomplete-file-row {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 5px;
      width: 100%;
      background-color: var(--primary);
      z-index: 1;
    }
  }

  // Remove the line height from cells - we'll use display grid to center the text
  .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    line-height: var(--line-height-tight) !important;
  }

  // Fix the padding on the tab headers to take the "selected tab" border into account
  .ag-tab {
    padding-bottom: calc(12px - var(--blh-25));
  }
  // Don't shrink icons when there's a lack of space
  .ag-header-icon, .ag-dnd-ghost-icon {
    flex-shrink: 0;
  }
  // Add a margin to the header menu icon
  .ag-header-icon.ag-header-cell-menu-button {
    margin-left: var(--blh-50);
  }

  // Default icon styles
  .ag-header-cell fa-duotone-icon {
    --fa-primary-color: var(--white)
  }

  .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
    background-color: var(--right-click-selection-colour);
  }

  .grid-cell-readonly {
    cursor: not-allowed;
    background-color: var(--readonly-cell-colour);
    color: var(--readonly-cell-text-color);
    &:hover {
      background-color: var(--readonly-cell-colour);
      color: var(--readonly-cell-text-color);
    }
    .drop-list-btn, .edit-btn {
      display: none;
    }
  }

  .ag-cell.quick-column {
    background-color: var(--quick-column-cell-colour);
  }

  .ag-sort-indicator-icon {
    color: var(--grid-indicator-icon-colour);
    .fa-secondary {
      opacity: 1;
    }
  }

  .grid-row-drop-zone {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: hsla(var(--primary-hue), 50%, 90%, 0.85);
    border: dashed 3px var(--primary);
    z-index: 999;
  }

  .ag-status-bar {
    max-width: calc(100vw - var(--current-sidebar-width-em));
    min-height: 16px;
    border-color: var(--rule-grid-status-bar-border-colour);
    overflow: hidden;
    .ag-status-name-value {
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: var(--xs);
      color: var(--text-mid2);
    }
    .ag-status-name-value-value {
      color: inherit;
    }
  }

  .ag-row-selected {
    border: 1px solid var(--range-selection-border-color)
  }

  .ag-theme-alpine textarea[class^=ag-] {
    min-height: calc(var(--ag-grid-size) * 12);
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right: var(--ag-borders-critical) var(--rule-grid-pinned-border-colour);
  }
}

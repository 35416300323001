@import 'mixins/utils-mixins'

.text-centre
  text-align: center !important

.visually-hidden
  +visually-hidden

.hide-in-place
  +hide-in-place

.display-none
  display: none !important

.self-right
  justify-self: flex-end

.no-pointer-events *:not(.grid-row-drop-zone)
  pointer-events: none

.mr-25
  margin-right: var(--blh-25)

.mt-50
  margin-top: var(--blh-50)

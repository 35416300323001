=ag-grid-variables
  //* the excel like grid *//
  --grid-header-height: 32px
  --grid-row-height: 32px
  --grid-cell-padding: 0 var(--blh-25)

  // AQL
  //--aql-line-colour: var(--grey-300)
  --aql-line-colour: hsla(var(--grey-hue), var(--grey-saturation), var(--600), 0.5)
  --aql-line-colour-light: hsla(var(--grey-hue), var(--grey-saturation), var(--100), 0.5)

@import './utils-mixins'
@import './positioning-mixins'

%button-color-dark
  color: white
  box-shadow: var(--button-dark-shadow)
  &:focus,
  &:active
    outline: none
    box-shadow: var(--button-dark-shadow-focus)

%button-light
  background-color: var(--button-light-bg)
  border-color: var(--button-light-border-colour)
  &:hover
    background-color: var(--button-light-bg--hover)
    border-color: var(--button-light-border-colour--hover)
=button-light
  @extend %button-light

%button-primary
  background-color: var(--primary)
  border-color: var(--primary-d050)
  @extend %button-color-dark
  &:hover
    background-color: var(--primary-d050)
    border-color: var(--primary-d050)
=button-primary
  @extend %button-primary

%button-primary-d
  background-color: var(--primary-d150)
  border-color: var(--primary-d200)
  @extend %button-color-dark
  &:hover
    background-color: var(--primary-d200)
    border-color: var(--primary-d200)
=button-primary-d
  @extend %button-primary-d

%button-warning
  background-color: var(--warning)
  border-color: var(--warning-d050)
  @extend %button-color-dark
  &:hover
    background-color: var(--warning-d050)
    border-color: var(--warning-d050)
=button-warning
  @extend %button-warning

%button-warning-d
  background-color: var(--warning-d150)
  border-color: var(--warning-d200)
  @extend %button-color-dark
  &:hover
    background-color: var(--warning-d200)
    border-color: var(--warning-d200)
=button-warning-d
  @extend %button-warning-d

%button-danger
  background-color: var(--danger)
  border-color: var(--danger-d050)
  @extend %button-color-dark
  &:hover:not(:disabled)
    background-color: var(--danger-d050)
    border-color: var(--danger-d050)
  &:disabled
    background-color: var(--danger-d150)
    color: var(--grey-600)
=button-danger
  @extend %button-danger

%button-danger-d
  background-color: var(--danger-d150)
  border-color: var(--danger-d200)
  @extend %button-color-dark
  &:hover
    background-color: var(--danger-d200)
    border-color: var(--danger-d200)
=button-danger-d
  @extend %button-danger-d

%button-success
  background-color: var(--success)
  border-color: var(--success-d050)
  @extend %button-color-dark
  &:hover
    background-color: var(--success-d050)
    border-color: var(--success-d050)
=button-success
  @extend %button-success

%button-muted
  color: var(--button-muted-text)
  background-color: var(--button-alt-bg-colour)
  box-shadow: none
  border-color: var(--button-light-border-colour)
  &:hover
    background-color: var(--button-alt-bg-colour--hover)
    border-color: var(--button-light-border-colour--hover)

%button-primary-alt
  background-color: var(--button-alt-bg-colour)
  color: var(--primary)
  border-color: var(--primary)
  &:hover
    background-color: var(--button-alt-bg-colour--hover)
    border-color: var(--primary-l200)
=button-primary-alt
  @extend %button-primary-alt

%button-danger-alt
  background-color: var(--button-alt-bg-colour)
  color: var(--danger)
  border-color: var(--danger)
  &:hover
    background-color: var(--button-alt-bg-colour--hover)
    border-color: var(--danger-l200)
=button-danger-alt
  @extend %button-danger-alt

%button-menu-child
  background: transparent
  box-shadow: none
  border: none
  border-radius: 0
  &:hover,
  &:active,
  &:focus
    text-decoration: none
    border: none
    box-shadow: none
  &:hover
    background: var(--grey-025)
  &:active,
  &:focus
    background: var(--grey-050)
  &:disabled
    background: var(--grey-075)
  .icon.left
    margin-right: var(--blh)
  .icon.right
    margin-left: var(--blh)

%button-disabled
  cursor: not-allowed
  background-color: var(--disabled-btn-bg-colour)
  color: var(--text-disabled)
  border-color: var(--disabled-btn-border-colour)
  box-shadow: none
  &:active
    text-decoration: none
  &:hover
    background-color: var(--disabled-btn-bg-colour)
    color: var(--text-disabled)
    .icon
      color: var(--text-disabled)
  .icon
    color: var(--text-disabled)
  .icon-clickable
    cursor: not-allowed

=nav-icon
  color: var(--nav-icon-colour)
  &:hover,
  &:focus,
  &:active,
  &.is-active
    color: var(--nav-icon-colour-active)



=button-group
  display: flex
  flex-wrap: wrap
  gap: var(--blh-50)
  &.btn-group--l
    gap: var(--blh)

=button-focus-surround($with-icon: false, $icon-shadow-size: 3px)
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    outline: none
    box-shadow: var(--button-light-shadow-focus)
    text-decoration: underline
    @if $with-icon == true
      app-icon,
      fa-icon,
      .icon
        background-color: var(--btn-icon-focus-surround-colour)
        box-shadow: 0 0 5px $icon-shadow-size var(--btn-icon-focus-surround-colour)
        border-radius: 25%

%button-reset
  +appearance-reset
  +unselectable
  cursor: pointer
  white-space: nowrap
  vertical-align: top
  text-decoration: none
  // States
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    outline: none
  &:disabled,
  fieldset:disabled &
    cursor: not-allowed

=button-frame
  @extend %button-reset
  letter-spacing: 1px
  text-align: center
  line-height: 1
  transition: var(--button-transition)
  // States
  +button-focus-surround
  &:disabled, &.disabled
    @extend %button-disabled

=button-framework($hasShadow: true, $inputSizing: true, $radius: var(--radius))
  +button-frame
  +inline-flex(flex-start)
  position: relative
  border: var(--control-border-width) solid transparent
  border-radius: $radius
  @if($inputSizing == true)
    height: var(--control-height)
  @if($hasShadow == true)
    box-shadow: var(--button-light-shadow)
    &:hover
      box-shadow: var(--button-light-shadow-hover)
  @else
    box-shadow: none

=button-muted
  @extend %button-muted

=background-highlight-base($colour: var(--grey-trans-050), $important: false, $amountV: 7px, $amountH: 7px)
  position: relative
  z-index: 0
  &::before
    content: ''
    position: absolute
    top: -$amountV
    left: -$amountH
    bottom: -$amountV
    right: -$amountH
    @if ($important == true)
      background-color: $colour !important
      box-shadow: 0 0 3px $colour !important
    @else
      background-color: $colour
      box-shadow: 0 0 3px $colour
    border-radius: var(--radius-l)
    transition: var(--button-transition)
    z-index: -1

=icon-background-highlight($colour: var(--grey-trans-100), $important: false)
  +background-highlight-base($colour, $important)

=icon-background-highlight-colorize($highlightColour: var(--grey-trans-100), $iconColour: var(--white), $important: false)
  &:hover .icon,
  &:focus .icon,
  &:active .icon,
  &.active .icon,
  &.open .icon
    color: $iconColour
    &::before
      @if ($important == true)
        background-color: $highlightColour !important
        box-shadow: 0 0 3px $highlightColour !important
      @else
        background-color: $highlightColour
        box-shadow: 0 0 3px $highlightColour

=icon-background-highlight-active-colorize($highlightColour: var(--grey-trans-100), $iconColour: var(--accent-icon-colour), $important: false)
  &:focus .icon,
  &:active .icon,
  &.active .icon,
  &.open .icon
    color: $iconColour
    &::before
      @if ($important == true)
        background-color: $highlightColour !important
        box-shadow: 0 0 3px $highlightColour !important
      @else
        background-color: $highlightColour
        box-shadow: 0 0 3px $highlightColour

=button-no-border
  box-shadow: none
  border: none
  border-radius: 0

=no-surround-icon-highlight($colour: 'dark')
  box-shadow: none
  fa-icon,
  .icon
    @if($colour == 'dark')
      +icon-background-highlight
    @if($colour == 'light')
      +icon-background-highlight(var(--white-trans-100))

=button-no-surround($colour: 'dark', $highlightOnDisabled: false)
  +button-no-border
  background: transparent
  &:hover,
  &:focus,
  &:active,
  &.active
    @if($highlightOnDisabled == false)
      &:not(:disabled):not(.disabled)
        +no-surround-icon-highlight($colour)
        &.highlight-active-accent
          +icon-background-highlight-active-colorize(var(--accent-trans-800), var(--accent-icon-colour))
    @else
      +no-surround-icon-highlight($colour)
      &.highlight-active-accent
        +icon-background-highlight-active-colorize(var(--accent-trans-800), var(--accent-icon-colour))
  &:disabled,
  &.disabled
    background-color: transparent !important
    box-shadow: none !important
    fa-icon,
    .icon
      @if($colour == 'light')
        color: var(--white)

=button-collapsed
  justify-content: center
  align-content: center
  background-color: var(--collapsed-button-bg-colour)
  color: var(--collapse-button-text-colour)
  +button-focus-surround(true, 5px)
  &:hover
    background-color: var(--collapsed-button-bg-colour--hover)

=button($fontSize: var(--sm), $padding: var(--blh-25) var(--blh), $hasShadow: true, $inputSizing: true, $radius: var(--radius))
  +button-framework($hasShadow, $inputSizing, $radius)
  font-size: $fontSize
  padding: $padding

=icon-button($fontSize: var(--sm), $padding: 0, $hasShadow: false, $inputSizing: true, $radius: var(--radius))
  +button($fontSize, $padding, $hasShadow, $inputSizing)
  +button-no-surround
  +button-extenders

=btn-menu
  display: grid
  gap: var(--blh-25)
  padding: var(--blh-50) 0
  background-color: var(--dropdown-menu-bg-colour)
  border: var(--border)
  border-radius: var(--radius)
  box-shadow: var(--shadow)

=toolbar-fill-button($bgColour: var(--primary), $bgColourHovered: var(--primary-d100))
  +button(var(--sm), 0 var(--blh), false, false, 0)
  +button-focus-surround(true, 8px)
  background-color: $bgColour
  +button-extenders
  &:active,
  &:focus,
  &:hover
    background-color: $bgColourHovered
  &:active,
  &:focus
    .icon
      box-shadow: 0 0 5px 8px var(--btn-icon-focus-surround-colour)
      border-radius: 25%
  &:disabled,
  &.disabled
    color: var(--grey-600)
    background-color: $bgColour
    border: var(--control-border-width) solid transparent

%input-height
  height: var(--input-height)
%button-padding
  padding: var(--button-padding)
%button-padding-xs
  padding: var(--button-padding-xs)
%button-padding-sm
  padding: var(--button-padding-sm)

// These should be extended at the end of each button parent class
=button-extenders
  &.btn--input-height
    @extend %input-height
  &.btn--sm,
  &.btn--xs
    font-size: var(--xs)
    letter-spacing: 0.5px
  &.btn--sm
    @extend %button-padding-sm
  &.btn--xs
    @extend %button-padding-xs
  &.with-icon
    &:focus,
    &:active,
    &.active
      &:not(:disabled):not(.disabled):not(.light)
        +no-surround-icon-highlight('dark')
    &.light:focus,
    &.light:active,
    &.light.active
      &:not(:disabled):not(.disabled)
        +no-surround-icon-highlight('light')

%toolbar-btn-base
  width: 1.5em
  justify-content: center
  &:disabled:not(.active) .icon
    color: var(--grey-300)
    &::before
      content: none

%toolbar-btn-dark
  +button-no-surround('dark', true)
  color: var(--grey-700)

%toolbar-btn-light
  +button-no-surround('light', true)
  color: var(--light)
  &.active:disabled,
  &.active.disabled
    color: var(--white)

=toolbar-btn($light: true)
  @extend %toolbar-btn-base
  @if ($light == true)
    @extend %toolbar-btn-light
  @else
    @extend %toolbar-btn-dark

=padding-variables
  --button-padding: var(--blh-25) var(--blh)
  --button-padding-xs: 0 var(--blh-50)
  --button-padding-sm: var(--blh-25) var(--blh-75)

=margin-padding-variables
  --body-pad: var(--blh)
  --modal-body-padding: var(--blh)

=margin-padding-variables-phablet
  --center-container-pad: var(--blh-200)
  --modal-body-padding: var(--blh-200)

=margin-padding-variables-tablet
  --center-container-pad: var(--blh-300)

=margin-padding-variables-tablet-wide
  --body-pad: var(--blh-200)
  --center-container-pad: var(--blh-400)
  --modal-body-padding: var(--blh-400)

=bg-image-variables
  //  BACKGROUND IMAGE ICON SVGs
  --checkbox-unchecked: url("data:image/svg+xml;charset=US-ASCII,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM384,400a16,16,0,0,1-16,16H80a16,16,0,0,1-16-16V112A16,16,0,0,1,80,96H368a16,16,0,0,1,16,16Z' style='fill:%230468bf;opacity:0.4000000059604645;isolation:isolate'/%3E%3Cpath d='M64,400V112A16,16,0,0,1,80,96H368a16,16,0,0,1,16,16V400a16,16,0,0,1-16,16H80A16,16,0,0,1,64,400Z' style='fill:%230468bf;'/%3E%3C/svg%3E")
  --checkbox-unchecked-hover: url("data:image/svg+xml;charset=US-ASCII,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM384,400a16,16,0,0,1-16,16H80a16,16,0,0,1-16-16V112A16,16,0,0,1,80,96H368a16,16,0,0,1,16,16Z' style='fill:%23034c8c;opacity:0.4000000059604645;isolation:isolate'/%3E%3Cpath d='M64,400V112A16,16,0,0,1,80,96H368a16,16,0,0,1,16,16V400a16,16,0,0,1-16,16H80A16,16,0,0,1,64,400Z' style='fill:%23034c8c;'/%3E%3C/svg%3E")
  --checkbox-checked: url("data:image/svg+xml;charset=US-ASCII,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM379.31,197.94l-184,184a16,16,0,0,1-22.62,0l-104-104a16,16,0,0,1,0-22.63h0l22.62-22.62a16,16,0,0,1,22.63,0h0L184,302.75,334.06,152.69a16,16,0,0,1,22.63,0h0l22.62,22.62a16,16,0,0,1,0,22.63Z' style='fill:%230468bf;opacity:0.4000000059604645;isolation:isolate'/%3E%3Cpath d='M195.31,381.94a16,16,0,0,1-22.62,0l-104-104a16,16,0,0,1,0-22.63h0l22.62-22.62a16,16,0,0,1,22.63,0h0L184,302.74l150.06-150a16,16,0,0,1,22.63,0h0l22.62,22.62a16,16,0,0,1,0,22.63h0l-184,184Z' style='fill:%230468bf'/%3E%3C/svg%3E")
  --checkbox-checked-hover: url("data:image/svg+xml;charset=US-ASCII,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM379.31,197.94l-184,184a16,16,0,0,1-22.62,0l-104-104a16,16,0,0,1,0-22.63h0l22.62-22.62a16,16,0,0,1,22.63,0h0L184,302.75,334.06,152.69a16,16,0,0,1,22.63,0h0l22.62,22.62a16,16,0,0,1,0,22.63Z' style='fill:%23034c8c;opacity:0.4000000059604645;isolation:isolate'/%3E%3Cpath d='M195.31,381.94a16,16,0,0,1-22.62,0l-104-104a16,16,0,0,1,0-22.63h0l22.62-22.62a16,16,0,0,1,22.63,0h0L184,302.74l150.06-150a16,16,0,0,1,22.63,0h0l22.62,22.62a16,16,0,0,1,0,22.63h0l-184,184Z' style='fill:%23034c8c'/%3E%3C/svg%3E")

  --checkbox-unchecked-light: url("data:image/svg+xml;charset=US-ASCII,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM384,400a16,16,0,0,1-16,16H80a16,16,0,0,1-16-16V112A16,16,0,0,1,80,96H368a16,16,0,0,1,16,16Z' style='fill:%23cccccc;opacity:0.4000000059604645;isolation:isolate'/%3E%3Cpath d='M64,400V112A16,16,0,0,1,80,96H368a16,16,0,0,1,16,16V400a16,16,0,0,1-16,16H80A16,16,0,0,1,64,400Z' style='fill:%23cccccc;'/%3E%3C/svg%3E")
  --checkbox-unchecked-light-hover: url("data:image/svg+xml;charset=US-ASCII,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM384,400a16,16,0,0,1-16,16H80a16,16,0,0,1-16-16V112A16,16,0,0,1,80,96H368a16,16,0,0,1,16,16Z' style='fill:%23d2d2d2;opacity:0.4000000059604645;isolation:isolate'/%3E%3Cpath d='M64,400V112A16,16,0,0,1,80,96H368a16,16,0,0,1,16,16V400a16,16,0,0,1-16,16H80A16,16,0,0,1,64,400Z' style='fill:%23d2d2d2;'/%3E%3C/svg%3E")
  --checkbox-checked-light: url("data:image/svg+xml;charset=US-ASCII,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM379.31,197.94l-184,184a16,16,0,0,1-22.62,0l-104-104a16,16,0,0,1,0-22.63h0l22.62-22.62a16,16,0,0,1,22.63,0h0L184,302.75,334.06,152.69a16,16,0,0,1,22.63,0h0l22.62,22.62a16,16,0,0,1,0,22.63Z' style='fill:%23d9d9d9;opacity:0.4000000059604645;isolation:isolate'/%3E%3Cpath d='M195.31,381.94a16,16,0,0,1-22.62,0l-104-104a16,16,0,0,1,0-22.63h0l22.62-22.62a16,16,0,0,1,22.63,0h0L184,302.74l150.06-150a16,16,0,0,1,22.63,0h0l22.62,22.62a16,16,0,0,1,0,22.63h0l-184,184Z' style='fill:%23d9d9d9'/%3E%3C/svg%3E")
  --checkbox-checked-light-hover: url("data:image/svg+xml;charset=US-ASCII,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM379.31,197.94l-184,184a16,16,0,0,1-22.62,0l-104-104a16,16,0,0,1,0-22.63h0l22.62-22.62a16,16,0,0,1,22.63,0h0L184,302.75,334.06,152.69a16,16,0,0,1,22.63,0h0l22.62,22.62a16,16,0,0,1,0,22.63Z' style='fill:%23dfdfdf;opacity:0.4000000059604645;isolation:isolate'/%3E%3Cpath d='M195.31,381.94a16,16,0,0,1-22.62,0l-104-104a16,16,0,0,1,0-22.63h0l22.62-22.62a16,16,0,0,1,22.63,0h0L184,302.74l150.06-150a16,16,0,0,1,22.63,0h0l22.62,22.62a16,16,0,0,1,0,22.63h0l-184,184Z' style='fill:%23dfdfdf'/%3E%3C/svg%3E")

  --chevron-down-primary: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%230468bf;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M441.9,167.3l-19.8-19.8a12,12,0,0,0-17,0L224,328.2,42.9,147.5a12,12,0,0,0-17,0L6.1,167.3a12,12,0,0,0,0,17L215.5,393.7a12,12,0,0,0,17,0L441.9,184.3a12,12,0,0,0,0-17Z'/%3E%3C/svg%3E")
  --chevron-down-primary-l200: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%232d9bfb;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M441.9,167.3l-19.8-19.8a12,12,0,0,0-17,0L224,328.2,42.9,147.5a12,12,0,0,0-17,0L6.1,167.3a12,12,0,0,0,0,17L215.5,393.7a12,12,0,0,0,17,0L441.9,184.3a12,12,0,0,0,0-17Z'/%3E%3C/svg%3E")
  --chevron-up-primary: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%230468bf;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M6.1,373.9l19.8,19.8a12,12,0,0,0,17,0L224,213,405.1,393.7a12,12,0,0,0,17,0l19.8-19.8a12,12,0,0,0,0-17L232.5,147.5a12,12,0,0,0-17,0L6.1,356.9a12,12,0,0,0,0,17Z'/%3E%3C/svg%3E")
  --chevron-up-primary-l200: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%232d9bfb;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M6.1,373.9l19.8,19.8a12,12,0,0,0,17,0L224,213,405.1,393.7a12,12,0,0,0,17,0l19.8-19.8a12,12,0,0,0,0-17L232.5,147.5a12,12,0,0,0-17,0L6.1,356.9a12,12,0,0,0,0,17Z'/%3E%3C/svg%3E")
  --chevron-down-disabled: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23808080;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M441.9,167.3l-19.8-19.8a12,12,0,0,0-17,0L224,328.2,42.9,147.5a12,12,0,0,0-17,0L6.1,167.3a12,12,0,0,0,0,17L215.5,393.7a12,12,0,0,0,17,0L441.9,184.3a12,12,0,0,0,0-17Z'/%3E%3C/svg%3E")

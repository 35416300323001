@import 'utils-mixins'
@import 'positioning-mixins'
@import 'form-mixins'

=option
  cursor: pointer
  max-width: initial
  margin: 0
  padding: var(--blh-50)
  line-height: 1.2
  border-bottom: 1px solid var(--dropdown-option-border-colour)
  overflow: hidden
  text-overflow: ellipsis
  &:last-child
    border-bottom: 0
  &.highlight
    background-color: var(--dropdown-option-bg-highlight-colour)
    overflow-wrap: break-word

=droplist-option
  +option
  +flex-flex(1, 0)

=multi-droplist-option
  +option
  display: grid
  grid-gap: var(--blh-50)
  grid-template-columns: max-content 1fr
  align-items: center
  &:hover
    background-color: var(--dropdown-option-bg-highlight-colour-l)
  label, input
    cursor: pointer

=options
  +unbulleted-list
  margin: 0
  overflow: auto

=droplist-options
  +options
  .option
    +droplist-option

=multi-droplist-options
  +options
  .option
    +multi-droplist-option

=multi-droplist-input-selected-option
  +flex-cc(initial, center)
  cursor: default
  flex-grow: 0
  margin: 0
  padding: 0.35em 0.35em 0.35em 0.5em
  font-size: var(--xs)
  line-height: 1
  color: var(--white)
  border-radius: 1.5em
  border: var(--border-solid-primary)
  background-color: var(--primary)
  span
    flex-shrink: 0
    line-height: 0
  button
    height: auto
  app-icon, .icon
    cursor: pointer
    transition: var(--transition)
    &:hover
      color: var(--grey-100)
    &.right
      margin-left: var(--blh-25)
  &.highlight
    border-color: var(--primary-d200)
    box-shadow: var(--button-dark-shadow)

=multi-droplist-input-selected-options
  +unbulleted-list
  +flex-cc(initial, center, var(--blh-25))
  flex-wrap: wrap
  min-height: 100%
  margin: 2px var(--blh-25) 0 0
  .selected-option
    +multi-droplist-input-selected-option

=no-options
  padding: var(--blh-50)
  line-height: 1.2

=fake-input($pad: null)
  @if $pad != null
    +input
  @else
    +input($pad)
  height: var(--input-height)

=dropdown-input-arrow($padRight: 'true')
  +appearance-reset
  position: relative
  cursor: pointer
  @if $padRight == 'true'
    padding-right: 2.35em
  z-index: 1
  background-repeat: no-repeat
  background-position: calc(100% - var(--blh-50)) 50%
  background-size: var(--select-arrow-size) var(--select-arrow-size)
  transition: background ease 150ms
  background-image: var(--chevron-down-primary-l200)
  &:hover
    background-image: var(--chevron-down-primary)
  &.open
    background-image: var(--chevron-up-primary-l200)
  &:hover.open
    background-image: var(--chevron-up-primary)
  &.disabled, &:disabled
    background-image: var(--chevron-down-disabled) !important
  .arrow-clickable
    position: absolute
    right: var(--blh-50)
    top: 50%
    transform: translateY(-50%)
    height: var(--select-arrow-size)
    width: var(--select-arrow-size)
    background-color: transparent
    z-index: 1

/**
 * @params $type = 'droplist'|'multi-droplist'
 */
=fake-select-disabled
  cursor: not-allowed
  background-color: var(--grey-trans-100)
  border-color: var(--grey-trans-150)
  color: var(--text-disabled)
  &.disabled-alt
    background-color: var(--grey-150)
  input
    background: transparent none !important
    border: none
  input::placeholder
    color: inherit !important

=options-container($type)
  width: 100%
  background-color: var(--dropdown-bg-colour)
  border: var(--border-focused)
  border-bottom-left-radius: var(--radius)
  border-bottom-right-radius: var(--radius)
  box-shadow: var(--shadow-bottom)
  overflow: auto
  @if $type == 'multi-droplist'
    display: grid
    grid-template-rows: max-content 1fr
  .options
    @if $type == 'multi-droplist'
      +multi-droplist-options
    @if $type == 'droplist'
      +droplist-options
      overflow: auto
  .no-options, app-loading-spinner
    +no-options

=fake-select
  display: grid
  grid-template-columns: 1fr 2.5em
  flex-wrap: wrap
  position: relative
  height: var(--input-height)
  padding: 0
  background-color: var(--input-bg-colour)
  z-index: 1
  &.open
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    border-color: var(--border-color-focus)
  &.open .options
    box-shadow: var(--shadow-bottom)
  &.error
    z-index: var(--z-index-error)
  &.open,
  &.open.error
    z-index: var(--z-index-overlay)
  button
    height: 100%
    justify-self: center
  .input, input
    padding: var(--blh-25) var(--blh-25) var(--blh-25) var(--blh)

=dropdown-button
  position: relative
  z-index: 50
  display: flex
  justify-content: flex-end
  align-items: center
  ::ng-deep
    &.btn-main
      border-top-right-radius: 0
      border-bottom-right-radius: 0
    &.btn-drop
      border-top-left-radius: 0
      border-bottom-left-radius: 0
    &.drop-list
      position: absolute
      top: calc(100% + var(--blh-25))
      right: 0
      text-align: right
      +unbulleted-list
    &.drop-list button
      box-shadow: var(--shadow-bottom)
